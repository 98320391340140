import getters from "./getters";
// import app from "./modules/app"
import auth from "./modules/auth";
import global from "./modules/global";

import notice from "./modules/notice";

import game from "./modules/game";
import gameRecord from "./modules/game-record";
import activity from "./modules/activity";

import wallet from "./modules/wallet";
import agent from "./modules/agent";

import { createStore } from "vuex";

export default createStore({
  modules: {
    global,
    auth,

    notice,
    game,
    gameRecord,
    activity,
    wallet,
    agent,
  },
});
