<template>
  <div class="page w-fill flex flex-1 flex-dc">
    <div class="wrap flex flex-1">
      <div
        class="content flex flex-1 flex-dc bor-8 p-20"
        style="background: var(--main)"
      >
        <img src="@/assets/images/agent-bonus.jpg" class="w-fill m-b-15" />
        <div class="color-hight">
          <strong
            >Ví dụ：<span>A</span> là cấp trên của <span>B</span>，Thì A sẽ nhận
            được 60/10000 Của B<br />
            Tức là:<br />
            B cược 10.000，Hoa hồng của A nhận được là 60<br />
            C cược 10.000，Hoa hồng của A nhận được là 20<br />
            D cược 10.000，Hoa hồng của A nhận được là 10<br />
            E cược 10.000，Hoa hồng của A nhận được là 5<br />
            F cược 10.000，Hoa hồng của A nhận được là 3<br />
            N cược 10.000，Hoa hồng của A nhận được là 1<br />
            <br />
            Cấp dưới trực tiếp，cấp trên được hoa hồng 60/10000<br />
            Ví dụ Hình：<br />
            B là cấp dưới trực tiếp của A<br />
            C là cấp dưới trực tiếp của B<br />
            D là cấp dưới trực tiếp của C<br />
            E là cấp dưới trực tiếp của D<br />
          </strong>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
// import LayoutFooter from "@/layout/layout-footer"
// import { UserOutlined, LockOutlined } from "@ant-design/icons-vue"

export default {
  name: "activity",
  components: {},
  data() {
    return {}
  },
  methods: {
    onShowLogin() {
      // this.$refs.login.show()
    },
  },
}
</script>

<style lang="less" scoped></style>
