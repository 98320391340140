export default {
  // 正整数
  number: (v) => (v * 1 ? parseInt(v) : undefined),
  // 自然数
  naturalNumber: (v) => (isNaN(parseInt(v)) ? undefined : parseInt(v)),
  // 千分符
  thousandSeparator: (v, dot = true, compute = true) => {
    if (v === undefined) return;
    if (compute) v = v / 100;
    let str = `${v}`;
    let floatStr = `${v}`;
    const hasDot = str.includes(".");
    const regexp = hasDot ? /(\d)(?=(\d{3})+\.)/g : /(\d)(?=(\d{3})+$)/g;
    str = str.replace(regexp, "$1,");
    !hasDot && dot && (str += ".00");
    return str;
  },
  // 数组字符串拼接
  arrJoinStr: (arr, attr, separator = ", ") =>
    arr?.reduce(
      (prev, item, i) =>
        prev + (i === 0 || !item[attr] ? "" : separator) + item[attr],
      ""
    ) ?? "",
  // 价格金额转整数
  priceToInteger: (v) => Math.round(v * 100),
  //只能输入数字或字母
  numberOrLetter: (v) => v.replace(/[^\w\.\/]/gi, ""),
  // 保留两位小数并且转化为%
  toPercent: (v) => Math.round(+v * 100) + "%",
  isIntegerDivision: (dividend, divisor) => dividend % divisor === 0,
};
