<style lang="less" scoped>
.reward-box {
  .img-box {
    background-image: url("@/assets/images/reward-box.png"); /* 设置图片路径 */
    background-size: 100% 100%;
    background-repeat: no-repeat; /* 背景图片不重复 */
    width: 160px;
    height: 128px;
  }
  img {
    width: 160px;
    object-fit: contain;
  }
  .icon {
    font-size: 30px;
    color: #98eeff;
  }
}
.reward-item.hide {
  visibility: hidden;
}
.icon-pre {
  visibility: hidden;
  transform: rotate(90deg);
}
.reward-item:nth-child(4n + 4) {
  .icon-next {
    display: none;
  }
}
.reward-item:nth-child(8n + 8) {
  .icon-pre {
    visibility: visible;
  }
}
.reward-item:nth-child(8n + 9) {
  .icon-pre {
    visibility: visible;
  }
}

.reward-item:nth-child(29),
.reward-item:nth-child(30),
.reward-item:nth-child(31),
.reward-item:nth-child(21),
.reward-item:nth-child(22),
.reward-item:nth-child(23),
.reward-item:nth-child(13),
.reward-item:nth-child(14),
.reward-item:nth-child(15),
.reward-item:nth-child(5),
.reward-item:nth-child(6),
.reward-item:nth-child(7) {
  .icon-next {
    transform: rotate(180deg);
  }
}
</style>
<template>
  <div class="page w-fill flex flex-1 flex-dc">
    <div class="wrap flex flex-1">
      <div
        class="content flex flex-1 flex-dc bor-8 p-20"
        style="background: var(--main)"
      >
        <!-- box-list -->
        <div class="flex flex-dc p-10">
          <!-- box-item -->
          <div class="reward-box flex flex-cc flex-sb flex-wrap">
            <div
              v-for="(i, index) in boxs"
              class="reward-item m-b-30 flex flex-dc flex-center"
              style="width: 25%"
              :class="i.hide ? 'hide' : ''"
            >
              <DoubleRightOutlined
                class="icon icon-pre p-rel"
                style="top: -20px"
              />
              <div class="p-rel flex flex-right flex-cc">
                <div class="img-box flex flex-center">
                  <div class="p-rel" style="top: 30px; color: #fff">
                    {{ i.number }}个人
                  </div>
                </div>
                <div class="p-abs" style="right: -50px">
                  <DoubleRightOutlined v-if="!i.last" class="icon icon-next" />
                </div>
              </div>
            </div>
          </div>
          <!-- desc -->
          <div
            class="hint-pack color-default bor-8 p-12"
            style="background: var(--sub)"
          >
            <div class="itemlast m-b-15">
              说明：满足累计充值大于或等于：<b>300000₫</b>，累计流水大于或等于：<b>0₫</b>，两个条件同时满足时即可领取：<b
                >40000₫</b
              >
            </div>
            <div class="itemlast">
              每日邀请奖励！ ！
              <br />每天邀请3名会员可获得3$奖励<br />每天邀请5位会员可获得6$奖励<br />每天邀请10位会员可获得10$奖励<br />每天邀请15名会员可获得16$奖励<br />一天邀请
              20 位会员，即可获得 25 美元的奖金！ ！ ！ !<br />联系导师自取，联系不到导师联系平台客服。<br />那么投注用户每天投注还可以获得流水返还。
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
// import LayoutFooter from "@/layout/layout-footer"
import { DoubleRightOutlined } from "@ant-design/icons-vue"
export default {
  name: "activity",
  components: {
    DoubleRightOutlined,
  },
  data() {
    return {
      boxs: [
        { number: 1, open: false },
        { number: 1, open: false },
        { number: 1, open: false },
        { number: 1, open: false },
        { number: 1, open: false },
        { number: 1, open: false },
        { number: 1, open: false },
        { number: 1, open: false },
        { number: 1, open: false },
        { number: 1, open: false },

        { number: 1, open: false },
        { number: 1, open: false },
        { number: 1, open: false },
        { number: 1, open: false },
        { number: 1, open: false },
        { number: 1, open: false },
        { number: 1, open: false },
        { number: 1, open: false },
        { number: 1, open: false },
        { number: 1, open: false },

        { number: 1, open: false },
        { number: 1, open: false },
        { number: 1, open: false },
        { number: 1, open: false },
        { number: 1, open: false },
        { number: 1, open: false },
        { number: 1, open: false },
        { number: 1, open: false },
        { number: 1, open: false },
        { number: 1, open: false },

        { number: 1, open: false },
        { number: 1, open: false },
        { number: 1, open: false },
        { number: 1, open: false, last: true },

        { number: 1, open: false, hide: true },
        { number: 1, open: false, hide: true },
      ],
    }
  },
  methods: {
    onShowLogin() {
      // this.$refs.login.show()
    },
  },
}
</script>
