<template>
  <div
    class="modal-page w-fill flex flex-1 flex-dc"
    style="background: var(--main)"
  >
    <div class="modal-wrap flex flex-1">
      <div class="modal-content flex flex-1 flex-dc">
        <div class="color-white p-t-15">
          <p
            class="fs-18 m-b-20 p-b-10 flex flex-cc flex-center"
            style="border-bottom: 1px solid var(--hight)"
          >
            <van-loading v-if="loading" size="18" class="m-r-4" />
            <span>{{ $t(`g.充值`) }}</span>
          </p>
          <div>
            <div>
              <p class="color-white m-b-10 bold">{{ $t(`g.存款金额`) }}</p>
              <a-input
                v-model:value="amount"
                class="m-b-10"
                style="height: 42px"
                placeholder="请输入金额"
              />
              <div class="amount-list flex flex-center flex-sb flex-wrap">
                <div
                  v-for="(i, index) in options"
                  style="width: 48%; height: 58px; background: var(--sub)"
                  class="recharge-item bor-8 flex flex-center m-b-12 pointer p-rel"
                  :class="i.cur ? 'cur' : ''"
                  @click="onChangeAmount(index)"
                >
                  <span class="color-white fs-16">{{ i.value }}</span>
                  <span
                    class="fs-12 p-abs color-txt1"
                    style="left: 2px; top: 2px"
                    >{{ $t(`g.赠送比例`) }}：{{ i.rate }}%</span
                  >
                </div>
              </div>
            </div>
            <div>
              <p class="color-white m-b-10 bold">{{ $t(`g.存款方式`) }}</p>
              <div class="amount-list flex flex-sb flex-wrap">
                <div
                  v-for="(i, index) in mode"
                  style="width: 48%; background: var(--sub)"
                  class="recharge-item bor-8 color-txt1 m-b-12 flex p-l-12 p-tb-10"
                  :class="i.cur ? 'cur' : ''"
                  @click="onChangeMode(index)"
                >
                  <img
                    :src="require(`@/assets/images/avatar.png`)"
                    style="width: 28px; object-fit: contain"
                  />
                  <div class="fs-12 flex flex-dc p-l-12">
                    <span>{{ i.name }}</span>
                    <!-- <span>最低{{ i.min }},最高{{ i.max }}</span> -->
                  </div>
                </div>
              </div>
            </div>
            <div class="m-t-30">
              <div
                @click="toNext"
                style="height: 40px"
                class="p-lr-10 bor-8 fs-16 bgs-default color-main pointer flex flex-center"
              >
                <van-loading v-if="loadingNext" size="16" class="m-r-4" />
                {{ $t(`g.下一步`) }}
              </div>
              <!-- <p class="t-center m-t-8">
                若充值遇到任何问题，联系<span class="color-default"
                  >在线客服</span
                >解决
              </p> -->
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="bottom-beat bg"></div>
    <UcModal ref="Cashier">
      <Cashier />
    </UcModal>
  </div>
</template>

<script>
import Cashier from "@/views/wallet/cashier.vue";

export default {
  name: "recharge",
  components: {
    Cashier,
  },
  data() {
    return {
      amount: undefined,
      // recharge
      options: [],
      mode: [],
      loading: false,
      loadingNext: false,
    };
  },
  async created() {
    this.$nextTick(async () => {
      this.loading = true;
      await this.$store.dispatch("wallet/loadPayMode");
      await this.$store.dispatch("wallet/loadOptions");
      this.loading = false;

      this.options = this.$store.state.wallet.options;
      this.mode = this.$store.state.wallet.payModes;
      this.mode[0].cur = true;
    });
  },
  methods: {
    onChangeAmount(index) {
      this.options.forEach((i) => (i.cur = false));
      this.options[index].cur = true;
      this.options.splice(0, 0);
      this.amount = +this.options[index].value;
    },
    onChangeMode(index) {
      this.mode.forEach((i) => (i.cur = false));
      this.mode[index].cur = true;
      this.mode.splice(0, 0);
    },
    async toNext() {
      let modeCur = this.mode.find((i) => i.cur);
      let optionCur = this.options.find((i) => i.cur);
      if (!modeCur) return this.mixMsg("请选择支付方式");
      if (!this.amount) return this.mixMsg("请输入充值金额");
      this.loadingNext = true;

      let res = await this.$store.dispatch("wallet/loadCode", {
        payment_method_id: this.mode.find((i) => i.cur).id,
        amount: this.amount,
      });

      this.loadingNext = false;

      this.$store.commit("wallet/setState", {
        attr: "order",
        state: res.data.data,
      });

      if (res && res.data && res.data.data.payment_url) {
        this.$store.commit("wallet/setState", {
          attr: "payUrl",
          state: res.data.data.payment_url,
        });
        // this.$refs.Cashier.show();
        this.$router.push("/cashier");
      }
    },
  },
};
</script>

<style lang="less" scoped>
.recharge-item.cur {
  background: var(--default) !important;
  span {
    color: var(--main) !important;
  }
}
</style>
