<template>
  <div class="layout-footer footer bgs-main p-18 p-b-20">
    <div class="footer-wrap">
      <div class="web-link flex flex-sb">
        <div class="">
          <div class="title">{{ $t(`g.娱乐城`) }}</div>
          <div class="content flex flex-dc color-7">
            <span>{{ $t(`g.投注记录`) }}</span>
            <span>{{ $t(`g.活动`) }}</span>
            <span>{{ $t(`g.会员`) }}</span>
            <span>{{ $t(`g.记录`) }}</span>
            <span>{{ $t(`g.账户记录`) }}</span>
            <span>{{ $t(`g.推广赚钱`) }}</span>
          </div>
        </div>
        <div class="">
          <div class="title">{{ $t(`g.娱乐城`) }}</div>
          <div class="content flex flex-dc">
            <span
              v-for="(i, index) in $store.state.global.tabs"
              @click="$router.push('/')"
            >
              <span v-if="i.id === 0">{{ $t(`g.${i.name}`) }}</span>
              <span v-else class="name">{{ i.name }}</span>
            </span>
          </div>
        </div>
        <!-- <div class="">
          <div class="title">{{ $t(`g.常见问题`) }}</div>
          <div class="content flex flex-dc">
            <span @click="onShowAbout">{{ $t(`g.关于`) }}</span>
            <span @click="onShowIntro">{{ $t(`g.介绍`) }}</span>
            <span @click="onShowOnline">{{ $t(`g.在线游戏`) }}</span>
            <span @click="onShowAward">{{ $t(`g.获奖者`) }}</span>
            <span @click="onShowContact">{{ $t(`g.联系我们`) }}</span>
          </div>
        </div> -->
        <div class="global-web flex flex-cc flex-dc">
          <div>
            <!-- <a href="www.baidu.com" target="_blank"> -->
            <img src="@/assets/images/web-x.png" alt="" />
            <!-- </a> -->
            <img src="@/assets/images/web-tiktok.png" alt="" />
            <img src="@/assets/images/web-facebook.png" alt="" />
            <img src="@/assets/images/web-ins.png" alt="" />
            <img src="@/assets/images/web-youtobe.png" alt="" />
            <img src="@/assets/images/18.png" alt="" />
          </div>
          <span class="color-white m-t-20">{{ $t(`g.全球站点`) }}</span>
        </div>
      </div>
      <div class="scroll-container flex flex-1 m-t-20">
        <div class="text" :class="{ 'animate-text': animateText }">
          <transition name="text-slide" mode="out-in">
            <div class="flex">
              <img src="@/assets/images/coop1.png" alt="" />
              <img src="@/assets/images/coop2.png" alt="" />
              <img src="@/assets/images/coop3.png" alt="" />
              <img src="@/assets/images/coop4.png" alt="" />
              <img src="@/assets/images/coop5.png" alt="" />
              <img src="@/assets/images/coop6.png" alt="" />
              <img src="@/assets/images/coop7.png" alt="" />
              <img src="@/assets/images/coop8.png" alt="" />
              <img src="@/assets/images/coop9.png" alt="" />
              <img src="@/assets/images/coop10.png" alt="" />
              <img src="@/assets/images/coop11.png" alt="" />
              <img src="@/assets/images/coop12.png" alt="" />
              <img src="@/assets/images/coop13.png" alt="" />
              <img src="@/assets/images/coop14.png" alt="" />
              <img src="@/assets/images/coop15.png" alt="" />
            </div>
          </transition>
        </div>
      </div>
      <div class="copyright flex flex-dc w-fill">
        <div class="m-t-20 color-white flex">
          Cassino de criptomoeda premiado. Com a sua abordagem centrada no
          jogador, o site é capaz de atender às necessidades de milhões de
          jogadores em todo o mundo. A principal prioridade do site é a
          comunidade, garantindo uma experiência de jogo atemporal e
          infinitamente divertida.
        </div>
        <div class="m-t-20 color-white t-center">
          Copyright © 2019-2024. All rights reserved.
        </div>
      </div>
    </div>
    <UcModal ref="about" :closeBottom="false">
      <div class="color-white p-15">
        <p class="t-center p-t-15 fs-18">关于我们</p>
        该公司成立于 1998
        年，为玩家提供各种在线赌场游戏，包括老虎机、桌面游戏、视频扑克、刮刮卡和真人赌场游戏，可在多种不同的设备上玩。该赌场已获得独立测试机构的认证。玩家可以在采用最新
        SSL
        加密技术的安全环境中享受游戏。赌场存款和取款有多种银行选项，包括主要信用卡和借记卡、网络钱包和预付费解决方案。专业支持团队随时准备通过电子邮件和实时聊天提供
        24/7 协助
      </div>
    </UcModal>
    <UcModal ref="intro" :closeBottom="false">
      <div class="color-white p-20">
        <p class="t-center fs-18">介绍</p>
        这是您在线赌场体验的开始。当您登录时，大厅为您提供了一种简单的方式来了解每日促销、旋转奖金轮或浏览新游戏。您会看到不同的游戏类别，例如老虎机、真人荷官游戏和累积奖金。查看网站上的所有功能。
      </div>
    </UcModal>
    <UcModal ref="online" :closeBottom="false">
      <div class="color-white p-20">
        <p class="t-center fs-18">在线游戏</p>
        您可以玩数百种真钱在线赌场游戏，包括经典的三卷轴和现代的五卷轴老虎机，以及大量的游戏内奖金功能，您还可以尝试二十一点和轮盘赌等桌面游戏。您还可以选择在真人荷官面前玩这些经典游戏，以及其他真人荷官游戏，如闪电轮盘和捕梦网。我们的赌场大厅还提供视频扑克、刮刮卡、在线宾果游戏和其他休闲游戏。
      </div>
    </UcModal>
    <UcModal ref="award" :closeBottom="false">
      <div class="color-white p-20">
        <p class="t-center fs-18">获奖者</p>
        无论是选择玩老虎机、视频扑克还是轮盘赌和二十一点等桌面游戏，无论是在桌面还是移动设备上，所有赌场游戏都充满乐趣，而且运气随时可能降临。自
        1998
        年以来，我们已经支付了很多获奖者，并且几乎每天都会有新的获奖者加冕。无论是老虎机或轮盘赌上的幸运旋转，视频扑克游戏中正确的手牌，还是二十一点中发的正确牌，很多玩家都已经体验过获胜手牌或旋转，并且继续这样做——看看下面我们目前的获奖者名单。
      </div>
    </UcModal>
    <UcModal ref="contact" :closeBottom="false">
      <div class="color-white p-20">
        <p class="t-center fs-18">联系我们</p>
        友好而专业的支持团队每周 7 天、每天 24
        小时为玩家解答任何疑问。支持以多种语言提供，可以通过电子邮件或实时聊天联系。实时聊天功能可以在桌面和移动设备上启动，对于任何可能需要立即关注的查询，建议选择该选项，而电子邮件可用于不太紧急的查询。无论是忘记密码还是提款查询，对于支持团队来说，没有什么问题太大或太小。
        1998
        年以来，我们已经支付了很多获奖者，并且几乎每天都会有新的获奖者加冕。无论是老虎机或轮盘赌上的幸运旋转，视频扑克游戏中正确的手牌，还是二十一点中发的正确牌，很多玩家都已经体验过获胜手牌或旋转，并且继续这样做——看看下面我们目前的获奖者名单。
      </div>
    </UcModal>
  </div>
</template>

<script>
export default {
  name: "layout-footer",
  components: {},
  data() {
    return {};
  },
  computed: {},
  methods: {
    onShowAbout() {
      this.$refs.about.show();
    },
    onShowIntro() {
      this.$refs.intro.show();
    },
    onShowOnline() {
      this.$refs.online.show();
    },
    onShowAward() {
      this.$refs.award.show();
    },
    onShowContact() {
      this.$refs.contact.show();
    },
  },
};
</script>

<style lang="less" scoped>
// footer
.footer {
  &-wrap {
    padding: 0 40px;
    max-width: 1200px;
    min-width: 1000px;
    margin: 0 auto;
    .title {
      color: #fff;
    }
    .content {
      color: #98eeff;
      span {
        margin-top: 15px;
      }
    }
    .content span:hover {
      cursor: pointer;
      color: #fff;
    }

    .global-web {
      img {
        width: 42px;
        margin-right: 7px;
        cursor: pointer;
      }
      img:last-child {
        margin-right: 0;
      }
    }
    .scroll-container {
      padding: 20px 0;
      border-top: 1px solid #2be1fa;
      border-bottom: 1px solid #2be1fa;
      img {
        height: 32px;
        margin: 0 20px;
      }
      .text {
        animation: slide-left 25s linear infinite;
      }
    }
  }
}

// 广播文字
.scroll-container {
  overflow: hidden;
  white-space: nowrap;
}

.text {
  display: inline-block;
  animation: slide-left 20s linear infinite;
}

@keyframes slide-left {
  0% {
    transform: translateX(100%);
  }
  100% {
    transform: translateX(-100%);
  }
}

.text-slide-enter-active,
.text-slide-leave-active {
  transition: all 0.5s;
}

.text-slide-enter-from,
.text-slide-leave-to {
  opacity: 0;
  transform: translateX(-100%);
}
</style>
