import { apiFactory } from "@/api/index";

export const gameApi = apiFactory.restful(`/api/betcenter_betrecord`);

export const gameTypeApi = apiFactory.command(`/api/providers`);
export const gameTypeSearchApi = (id) =>
  apiFactory.command(`/api/providers/${id}/games`);
export const gameLaunchApi = (id) =>
  apiFactory.command(`/api/provider-games/${id}/launch`);

export const gameBettingRecordApi = apiFactory.command(`/api/provider-bets`);

// export const gameBettingRecordDetailsApi =
//   apiFactory.restful(`/api/getbetdetail`);

// export const gameRecordApi = apiFactory.command(`/api/get_letters`);

// export const gameRecordDetailsApi = apiFactory.command(
//   `/api/announcementdetail_get`
// );
