import { message } from "ant-design-vue";
import createRestfulModule from "./restful-module";
import {
  noticeApi as listApi,
  noticeDetailsApi as detailsApi,
  homeApi,
} from "@/api/modules/notice";
import { gameTypeApi, gameTypeSearchApi } from "@/api/modules/game";

const store = {
  state: {
    menuShow: false,
    menu: [
      // menu1
      // { name: "热门", img: "menu-hot", cur: true },
      // { name: "电子", img: "menu-777" },
      // { name: "捕鱼", img: "menu-shark" },
      // { name: "真人", img: "menu-woman" },
      // { name: "足球", img: "menu-ball" },
      // { name: "大奖", img: "menu-gift" },
      // { name: "最近", img: "menu-time" },
      // { name: "收藏", img: "menu-start" },

      // menu2
      // { label: "热门", img: "menu-hot", cur: true },
      { label: "电子", img: "menu-777", cur: true },
      // { label: "游戏", img: "menu-shark" },
      // { label: "客服", img: "menu-woman" },
      // { label: "最近", img: "menu-time" },
      // { label: "收藏", img: "menu-start" },
    ],
    navTabIdx: 0,
    tabs: [],
    tabKey: 0,
  },
  // getters: {
  //   CHANNEL_OPTIONS: (state) =>
  //     useTransformOptions(state.channel, "Name", "ID"),
  // },
  mutations: {
    setMenuShow(state, data) {
      console.log("setMenuShow");
      state.menuShow = data;
    },
    setState(state, data) {
      state[data.attr] = data.state;
    },
  },
  actions: {
    async loadList({ _, commit }, data) {
      const res = await listApi.post(data);
      commit("setList", res.Data);
    },
    async loadTabs({ _, commit }) {
      let res = await gameTypeApi.get({});
      let tabs = { id: 0, name: "热门", code: "hot", img: "menu-hot" };
      res.data.data.forEach((i) => {
        if (i.id === 1) i.img = "menu-777";
      });

      res.data.data.unshift(tabs);
      commit("setState", { attr: "tabs", state: res.data.data });
    },
    async getDetails({ _, commit }, data) {
      let res = await detailsApi.post(data);
      // return await noticeDetailsApi.post(data)
      commit("setDocument", res.Data.announcement[0]);
    },
    async loadHomeInfo({ _, commit }, data) {
      const res = await listApi.post(data);
      commit("setList", res.Data);
    },
  },
};

export default createRestfulModule(store);
