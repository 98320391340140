<template>
  <div
    class="modal-page w-fill flex flex-1 flex-dc"
    style="background: var(--main)"
  >
    <UcTopBar title="投注记录" />
    <div class="modal-wrap flex flex-1 flex-dc p-0">
      <div class="content flex flex-1 flex-dc m-h-100" style="overflow: auto">
        <div class="card-list m-b-15">
          <div
            class="card-item flex flex-dc p-15"
            style="
              background: var(--main);
              color: var(--txt1);
              border-bottom: 1px solid var(--hight);
            "
            v-for="(i, index) in $store.state.game.bettingRecords"
          >
            <div class="flex flex-sb m-b-4">
              <span>{{ i.provider_game.name }}</span>
              <span>
                {{ $t(`g.有效投注`) }}
                <span style="color: #e7e7e7" class="m-l-4">{{ i.amount }}</span>
              </span>
              <span>
                {{ $t(`g.输赢`) }}
                <span style="color: #55cf76" class="m-l-4"
                  >{{ i.profit < 0 ? "-" : "+" }}{{ i.profit }}</span
                >
              </span>
            </div>
            <div class="flex flex-sb">
              <span>{{ $dayjs(i.bet_at).format("YYYY-MM-DD HH:mm:ss") }}</span>
              <span>
                {{ $t(`g.注单号`) }}
                <span class="m-l-4">{{ i.provider_bet_id }}</span>
              </span>
            </div>
          </div>
          <div class="more flex flex-center pointer m-t-20">
            <div
              @click="onMore"
              v-if="
                $store.state.game.bettingRecords.length !==
                $store.state.game.bettingRecordMeta.total
              "
            >
              <span style="color: #ffefba" class="flex flex-cc">
                <van-loading
                  v-if="$store.state.game.loading"
                  size="14"
                  class="m-r-4"
                />
                <span class="m-r-8"> {{ $t(`g.更多`) }}</span>
                <DoubleRightOutlined
                  class="fs-12"
                  style="transform: rotate(90deg)"
                />
              </span>
            </div>
            <div v-else>
              <span style="color: #ffefba">
                <span class="m-r-8"> {{ $t(`g.没有更多了`) }}</span>
              </span>
            </div>
          </div>
        </div>
      </div>
      <div class="bottom-beat bg"></div>
    </div>
  </div>
</template>

<script>
export default {
  name: "xx",
  data() {
    return {
      page: 1,
    };
  },
  async created() {
    this.$nextTick(async () => {
      this.init();
    });
  },
  methods: {
    async init() {
      await this.$store.dispatch("game/loadBettingRecord", {
        page: this.page,
      });
    },
    onMore() {
      this.page++;
      this.init();
    },
  },
};
</script>

<style lang="less" scoped></style>
