<template>
  <div class="page w-fill flex flex-1 flex-dc">
    <UcTopBar
      :title="$store.state.game.document.name"
      :isI18n="false"
      :loading="$store.state.game.loading"
    />
    <div class="flex flex-1">
      <iframe :src="url"></iframe>
    </div>
  </div>
</template>

<script>
export default {
  name: "PLAY",
  components: {},
  data() {
    return {
      url: "",
    };
  },
  async created() {
    try {
      let res = await this.$store.dispatch("game/getGameLaunch", {
        id: this.$route.query.id,
        _this: this,
      });
      if (res && res.data && res.data.url) {
        this.url = res.data.url;
      } else {
        throw "未获取到游戏地址，请重试";
      }
    } catch (e) {
      this.mixMsg(e, "error");
    }
  },
  methods: {},
};
</script>

<style lang="less" scoped>
iframe {
  border: none;
  height: 100%;
  width: 100%;
  // position: fixed;
  // z-index: 9999;
  left: 0;
  top: 0;
  background: #fff;
}
</style>
