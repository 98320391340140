<template>
  <div class="modal-page w-fill flex flex-1 flex-dc">
    <div class="modal-wrap flex flex-1">
      <div class="modal-content flex flex-1 flex-dc">
        <div class="color-white p-t-15">
          <p
            class="t-center fs-18 m-b-20 p-b-10"
            style="border-bottom: 1px solid var(--hight)"
          >
            {{ $t(`g.提现`) }}
          </p>
          <div>
            <div>
              <p class="color-white m-b-10 bold t-center fs-18">
                {{ $t(`g.账户余额`) }}
              </p>
              <p class="t-center fs-18 color-default">
                {{ $store.state.wallet.balance }}
              </p>
              <a-input
                v-model:value="form.payee_name"
                class="m-b-10"
                style="height: 42px"
                :placeholder="$t(`g.收款人姓名`)"
              />
              <a-select
                v-model:value="form.account_type"
                class="w-fill bor-8 m-b-10"
                style="height: 42px; border-radius: 8px"
                :placeholder="$t(`g.收款账号类型`)"
              >
                <a-select-option
                  :value="i.value"
                  v-for="(i, index) in $enums.txType.options()"
                  >{{ i.label }}</a-select-option
                >
              </a-select>
              <a-input
                v-model:value="form.payee_account"
                class="m-b-10"
                style="height: 42px"
                :placeholder="$t(`g.收款人账号`)"
              />
              <a-input
                v-model:value="form.amount"
                class="m-b-10"
                style="height: 42px"
                :placeholder="$t(`g.提现金额`)"
              />
            </div>
            <div class="m-t-30">
              <div
                @click="handleSubmit"
                style="height: 40px"
                class="p-lr-10 bor-8 fs-16 bgs-default color-main pointer flex flex-center m-l-15"
              >
                <van-loading v-if="loading" />{{ $t(`g.确认`) }}
              </div>
            </div>
            <div class="color-txt1 flex flex-dc m-t-20 fs-12">
              <!-- <div>今天还可以提现 5 次</div> -->
              <!-- <div>1. 可提现金额: 您的充值本金需要消费满 100% 方可提款</div>
              <div>2. 单笔取款 : 最低 50,000 最高 1,000,000,000</div> -->
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
// import LayoutFooter from "@/layout/layout-footer"
// import { UserOutlined, LockOutlined } from "@ant-design/icons-vue"

export default {
  name: "withdrawal",
  components: {},
  data() {
    return {
      loading: false,
      form: {},
    };
  },
  methods: {
    async handleSubmit() {
      const { payee_name, account_type, payee_account, amount } = this.form;
      if (!payee_name) return this.mixMsg("请输入收款人姓名");
      if (!account_type) return this.mixMsg("请选择收款账户类型");
      if (!payee_account) return this.mixMsg("请输入收款人账号");
      if (!amount) return this.mixMsg("请输入提现金额");

      this.loading = true;

      try {
        let res = await this.$store.dispatch(
          "wallet/withdrawalApply",
          this.form
        );
        if (res) {
          this.mixMsg(this.$t(`g.操作成功`));
          this.$parent.$parent.$parent.close();
          this.loading = false;
        } else {
          throw "操作失败";
        }
      } catch (e) {
        this.mixMsg(this.$t(`g.操作失败`));
        this.$parent.$parent.$parent.close();
      }
    },
  },
};
</script>

<style lang="less" scoped></style>
