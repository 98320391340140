<template>
  <div class="page w-fill flex flex-1 flex-dc">
    <div class="wrap flex flex-1">
      <div class="content flex flex-1 flex-dc">11</div>
    </div>
  </div>
</template>

<script>
// import LayoutFooter from "@/layout/layout-footer"
// import { UserOutlined, LockOutlined } from "@ant-design/icons-vue"

export default {
  name: "activity",
  components: {},
  data() {
    return {}
  },
  methods: {
    onShowLogin() {
      // this.$refs.login.show()
    },
  },
}
</script>

<style lang="less" scoped></style>
