<template>
  <div class="modal-page w-fill flex flex-1 flex-dc">
    <div class="modal-wrap flex flex-1">
      <div class="modal-content flex flex-1 flex-dc">
        <div class="color-white p-t-15">
          <p
            class="t-center fs-18 m-b-20 p-b-10"
            style="border-bottom: 1px solid var(--hight)"
          >
            添加银行卡
          </p>
          <div>
            <div>
              <a-input
                v-model:value="form.name"
                class="m-b-10"
                style="height: 42px"
                placeholder="姓名"
              />
              <a-input
                v-model:value="form.bank"
                class="m-b-10"
                style="height: 42px"
                placeholder="银行"
              />
              <a-input
                v-model:value="form.account"
                class="m-b-10"
                style="height: 42px"
                placeholder="账号"
              />
              <a-input
                v-model:value="form.accountTwo"
                class="m-b-10"
                style="height: 42px"
                placeholder="确认账号"
              />
              <a-input
                v-model:value="form.aqPwd"
                class="m-b-10"
                style="height: 42px"
                placeholder="请输入您的安全密码"
              />
            </div>
            <div class="m-t-30">
              <div
                @click="handleSubmit"
                style="height: 40px"
                class="p-lr-10 bor-8 fs-16 bgs-default color-main pointer flex flex-center m-l-15"
              >
                确认
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "bank-add",
  components: {},
  data() {
    return {
      form: {},
    }
  },
  methods: {
    handleSubmit() {
      // this.$refs.login.show()
    },
  },
}
</script>

<style lang="less" scoped></style>
