<template>
  <div class="vip w-fill flex flex-dc">
    <div class="uc-wrap flex flex-1">
      <div class="activity-content flex flex-1 flex-dc p-t-50">
        <div class="flex flex-dc">
          <div
            style="background: #0093b1"
            class="bor-8 p-20 p-tb-30 flex flex-cc flex-sb m-b-20"
          >
            <div>
              <img
                class="m-r-30"
                src="@/assets/images/avatar.png"
                style="border-radius: 50%; width: 50px"
              />
              <span>
                头衔:黄金 积分:1000 距离下一级需要 4000 分 每充值10000加1分
              </span>
            </div>
            <div class="flex">
              <div
                class="color-main bor-8 p-tb-6 p-lr-20 pointer m-r-15"
                style="background: #ffdd6c; border-radius: 5px"
              >
                充值
              </div>
              <div
                class="color-main bor-8 p-tb-6 p-lr-20 pointer"
                style="background: #ffefba; border-radius: 5px"
              >
                下注
              </div>
            </div>
          </div>
          <a-table
            class="w-fill bor-8"
            :dataSource="dataSource"
            :columns="columns"
            :pagination="false"
          />
        </div>
      </div>
    </div>
    <LayoutFooter />
  </div>
</template>

<script>
import LayoutFooter from "@/layout/layout-footer"
import { UserOutlined, LockOutlined } from "@ant-design/icons-vue"

export default {
  name: "activity",
  components: {
    UserOutlined,
    LockOutlined,
    LayoutFooter,
  },
  data() {
    return {
      dataSource: [
        {
          dj: "VIP1",
          tx: "新手",
          czjf: "0",
        },
      ],
      columns: [
        {
          title: "等级",
          dataIndex: "dj",
          key: "dj",
        },
        {
          title: "头衔",
          dataIndex: "tx",
          key: "tx",
        },
        {
          title: "成长积分",
          dataIndex: "czjf",
          key: "czjf",
        },
      ],
    }
  },
  methods: {
    onShowLogin() {
      // this.$refs.login.show()
    },
  },
}
</script>

<style lang="less" scoped>
.list {
  .item {
    transition: all 1s;
    position: relative;
    top: 0;
    cursor: pointer;
  }
  .item:hover {
    position: relative;
    top: -4px;
  }
}
</style>
