<template>
  <div class="topBar flex w-fill">
    <!-- 占位 -->
    <div class="topBar-seat"></div>
    <div class="topBar-wrap p-fix w-fill flex flex-sb flex-cc color-white">
      <!-- 内容 -->
      <div
        class="left flex flex-center"
        :class="back ? '' : 'v-hide'"
        @click="toBack"
      >
        <LeftOutlined v-show="back" />
      </div>
      <div class="middle fs-16 flex flex-cc" v-if="isI18n">
        <van-loading v-if="loading" size="14" class="m-r-4" />
        {{ $t(`g.${title}`) }}
      </div>

      <div class="middle fs-16 flex flex-cc" v-else>
        <van-loading v-if="loading" size="14" class="m-r-4" />{{ title }}
      </div>
      <div class="right v-hide">
        <LeftOutlined />
      </div>
    </div>
  </div>
</template>

<script>
import { LeftOutlined } from "@ant-design/icons-vue";

export default {
  name: "uc-topBar",
  components: {
    LeftOutlined,
  },
  props: {
    title: {
      type: String,
      default: "bottom",
    },
    back: {
      type: Boolean,
      default: true,
    },
    isI18n: {
      type: Boolean,
      default: true,
    },
    loading: {
      type: Boolean,
      default: false,
    },
  },

  data() {
    return {
      visible: false,
    };
  },
  methods: {
    onChangeLang(item) {
      this.$i18n.locale = item.lang;
      this.visible = false;
    },
    toBack() {
      this.$router.back();
    },
  },
};
</script>

<style lang="less">
.topBar {
  height: var(--topBarH);
  width: 100%;
  &-seat {
    height: var(--topBarH);
    width: 100%;
  }
  &-wrap {
    height: var(--topBarH);
    // left: 0;
    top: 0;
    z-index: 999;
    background: var(--main);
    border-bottom: 1px solid var(--barBor);
    max-width: var(--mixWidM);
    .left {
      width: var(--topBarH);
      height: var(--topBarH);
    }
    .right {
      width: var(--topBarH);
      height: var(--topBarH);
    }
  }
}
</style>
