<template>
  <div class="modal-page w-fill flex flex-1 flex-dc">
    <div class="modal-wrap flex flex-1">
      <div class="modal-content flex flex-1 flex-dc">
        <div class="color-white p-t-15">
          <p
            class="t-center fs-18 m-b-20 p-b-10"
            style="border-bottom: 1px solid var(--hight)"
          >
            意见反馈
          </p>
          <div>
            <div>
              <a-input
                v-model:value="form.title"
                class="m-b-10"
                style="height: 42px"
                placeholder="标题"
              />
              <a-textarea
                style="min-height: 150px"
                v-model:value="form.txt"
                placeholder="您的意见对我们很有价值。任何有价值的建议都会被考虑，一旦被采纳，重要程度将决定现金奖励。欢迎提出建议！"
                auto-size
              />
            </div>
            <div class="m-t-30">
              <div
                @click="handleWithdrawal"
                style="height: 40px"
                class="p-lr-10 bor-8 fs-16 bgs-default color-main pointer flex flex-center m-l-15"
              >
                提交
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "withdrawal",
  components: {},
  data() {
    return {
      form: {},
    }
  },
  methods: {
    onShowLogin() {
      // this.$refs.login.show()
    },
  },
}
</script>

<style lang="less" scoped></style>
