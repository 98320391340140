import { apiFactory } from "@/api/index";

// 充值
export const recharge = {
  topupsApi: apiFactory.command(`/api/topups`),
  optionsApi: apiFactory.command(`/api/topup-options`),
  payModeApi: apiFactory.command(`/api/payment-methods`),
  balanceApi: apiFactory.command(`/api/user/balance`),

  withdrawalApi: apiFactory.command(`/api/withdrawals`),
  txApi: apiFactory.command(`/api/transactions`),
};
