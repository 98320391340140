import createRestfulModule from "./restful-module";
import { recharge } from "@/api/modules/wallet";
import { useTransformOptions } from "@/utils/functions";

const store = {
  state: {
    game: [],
    options: [],
    payModes: [],
    order: {},
    balance: 0,
    payUrl: "",
    rechargeRecords: [],
    rechargeRecordMeta: {},
    txs: [],
    txMeta: {},
    withdrawals: [],
    withdrawalMeta: {},
    payUrl: "",
  },
  mutations: {
    setState(state, data) {
      state[data.attr] = data.state;
    },
  },
  actions: {
    async loadCode({ _, commit }, data) {
      return await recharge.getCodeApi.post(data);
    },
    async loadPayMode({ _, commit }, data) {
      try {
        const res = await recharge.payModeApi.get();
        commit("setState", { attr: "payModes", state: res.data.data });
      } catch (e) {
        console.log("eee", e);
      }
    },
    async loadOptions({ _, commit }, data) {
      try {
        const res = await recharge.optionsApi.get();
        commit("setState", { attr: "options", state: res.data.data.options });
      } catch (e) {}
    },
    async getUrl({ _, commit }, data) {
      return await recharge.topupsApi.get();
    },
    async loadCode({ _, commit }, data) {
      return await recharge.topupsApi.post(data);
    },
    async loadBalance({ _, commit, rootState }, _this) {
      try {
        let res = await recharge.balanceApi.get();
        commit("setState", { attr: "balance", state: res.data.data.balance });
      } catch (e) {
        if (e.status === 401) {
          setTimeout(() => {
            _this.$store.commit("auth/setUserInfo", {});
            _this.$refs.login.show();
          }, 0);
        }
      }
    },
    async loadRechargeRecord({ _, commit, state }, data) {
      try {
        const res = await recharge.topupsApi.get(data);
        let list = state.rechargeRecords.concat(res.data.data);
        commit("setState", { attr: "rechargeRecords", state: list });
        commit("setState", {
          attr: "rechargeRecordMeta",
          state: res.data.meta,
        });
      } catch (e) {}
    },
    async loadTxRecord({ _, commit, state }, data) {
      try {
        const res = await recharge.txApi.get(data);
        let list = state.txs.concat(res.data.data);
        commit("setState", { attr: "txs", state: list });
        commit("setState", { attr: "txMeta", state: res.data.meta });
      } catch (e) {}
    },
    async loadWithdrawalRecord({ _, commit, state }, data) {
      try {
        const res = await recharge.withdrawalApi.get(data);
        let list = state.withdrawals.concat(res.data.data);
        commit("setState", { attr: "withdrawals", state: list });
        commit("setState", { attr: "withdrawalMeta", state: res.data.meta });
      } catch (e) {}
    },
    async withdrawalApply({ _, commit, state }, data) {
      try {
        const res = await recharge.withdrawalApi.post(data);
      } catch (e) {}
    },
  },
};

export default createRestfulModule(store);
