<template>
  <div class="modal-page w-fill flex flex-1 flex-dc">
    <div class="modal-wrap flex flex-1">
      <div class="modal-content flex flex-1 flex-dc"></div>
    </div>
  </div>
</template>

<script>
// import LayoutFooter from "@/layout/layout-footer"
// import { UserOutlined, LockOutlined } from "@ant-design/icons-vue"

export default {
  name: "game-details",
  components: {},
  data() {
    return {}
  },
  methods: {
    onShowLogin() {
      // this.$refs.login.show()
    },
  },
}
</script>

<style lang="less" scoped></style>
