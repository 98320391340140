import createRestfulModule from "./restful-module";
import Cookies from "js-cookie";
import {
  loginApi,
  csrfApi,
  registerApi,
  yzmApi,
  userinfoApi,
  logoutApi,
  updatePwdApi,
  updateInfoApi,
} from "@/api/modules/auth";
import { message } from "ant-design-vue";

const getDefaultState = () => {
  return {
    token: getToken(),
    name: "",
    avatar: "",
  };
};

const authStore = {
  state: {
    name: "auth",
    userInfo: JSON.parse(localStorage.getItem("userInfo")) || {},
    // ...getDefaultState(),
  },
  mutations: {
    setUserInfo(state, data) {
      state.userInfo = data;
      localStorage.setItem("userInfo", JSON.stringify(data));
    },
    setToken: (state, token) => {
      state.token = token;
      localStorage.setItem("token", token);
    },
    RESET_STATE: (state) => {
      Object.assign(state, getDefaultState());
    },
    SET_TOKEN: (state, token) => {
      state.token = token;
    },
  },
  actions: {
    async csrf({ commit }) {
      await csrfApi.get({});
    },
    async login({ commit }, { form }) {
      await csrfApi.get({});
      try {
        let res = await loginApi.post(form);
        return res;
      } catch (e) {
        message.error("登录错误，请重试");
      }
    },
    async register({ commit }, { form, that }) {
      await csrfApi.get({});
      return await registerApi.post(form);
    },
    async getUserInfo({ commit }) {
      let res = await userinfoApi.get({});
      commit("setUserInfo", res.data.data);
    },
    async updatePwd({ commit }, data) {
      return await updatePwdApi.get(data);
    },
    async updateInfo({ commit }, data) {
      return await updateInfoApi.get(data);
    },
    async getYzm({ commit }, data) {
      return await yzmApi.post(data);
    },
    async logout({ commit, state }) {
      try {
        let res = await logoutApi.post();
        if (res) {
          commit("setUserInfo", {});
        } else {
          throw res;
        }
        // var cookies = document.cookie.split(";");
        // for (var i = 0; i < cookies.length; i++) {
        //   var cookie = cookies[i];
        //   var eqPos = cookie.indexOf("=");
        //   var cookieName = eqPos > -1 ? cookie.substr(0, eqPos) : cookie;
        //   var cookiePath = location.pathname.replace(/^\//, "");
        //   document.cookie =
        //     cookieName +
        //     "=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/; domain=.178t.com;" +
        //     cookiePath +
        //     ";";
        // }
      } catch (e) {
        console.log("logout e", e);
      }
    },
  },
};

export default createRestfulModule(authStore);
