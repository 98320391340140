import axios from "axios";
import { message } from "ant-design-vue";
import router from "@/router";
import i18n from "@/utils/lang/i18n";

const lang = navigator.language || navigator.userLanguage; //常规浏览器语言和IE浏览器
const langObj = {
  cn: "zh-CN",
  zh: "zh-TW",
  vn: "vi",
};

// 创建 axios 实例
const service = axios.create({
  baseURL: "https://api.games.178t.com",
  withCredentials: true,
  withXSRFToken: true,
  timeout: 30000,
  headers: {
    "Content-Type": "application/json;charset=utf-8",
    // "X-Requested-With": "XMLHttpRequest",
    "Accept-Language": langObj[i18n.global.locale] || langObj[i18n.locale], //"vi",
  },
});

// request 请求拦截器
service.interceptors.request.use(
  (config) => {
    // console.log("config333", config)
    // const { state } = useStore()
    // state.token && (config.headers['Authorization'] = `Bearer ${state.token}`) // header 添加 token

    // 默认需要个表单值 isNew
    // let a = "xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx".replace(
    //   /[xy]/g,
    //   function (t) {
    //     let e = (16 * Math.random()) | 0,
    //       a = "x" == t ? e : (3 & e) | 8;
    //     return a.toString(16);
    //   }
    // );

    // if (config.data === undefined) {
    //   config.data = "isNew=" + a;
    // } else {
    //   // config.data = config.data + "&" + "isNew=" + a
    //   config.data.isNew = a;
    // }

    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);

// respone 响应拦截器
service.interceptors.response.use(
  (res) => {
    // console.log("response data", res);
    // return data?.data || data;
    return res;
  },
  ({ response, message: msg }) => {
    switch (response.status) {
      case 401:
        // clearToken();
        // router.push({ name: "admin-auth" });
        message.error("请先登录");
        // console.log("response", response);
        return Promise.reject({
          status: response.status,
          data: {
            data: null,
          },
          msg: "请先登录",
        });
      case 403:
      // router.push("/403");
      // return Promise.reject(response.status);
      case 404:
        message.error("请求路径错误");
        return Promise.reject(response.status);
      case 409:
        message.error("操作失败：提交了重复的内容");
        return Promise.reject(response.status);
      case 500:
        message.error("服务器发生了一个错误，请稍后再试");
        return Promise.reject(response.status);
    }

    // message.error("请求错误", response.data.message);
    // return Promise.reject(res.data.msg);
    return Promise.reject("请求错误");
  }
);

export default service;
