<template>
  <div class="page w-fill flex flex-1 flex-dc">
    <UcTopBar title="客服" />
    <div class="flex flex-1">
      <iframe :src="url"></iframe>
    </div>
  </div>
</template>

<script>
export default {
  name: "PLAY",
  components: {},
  data() {
    return {
      url: "",
    };
  },
  async created() {
    this.url =
      "https://api.mkf326.win/standalone.html?appId=9f54236f53f640858e6361c3a041d114&lang=vi";
  },
  methods: {},
};
</script>

<style lang="less" scoped>
iframe {
  border: none;
  height: 100%;
  width: 100%;
  // position: fixed;
  // z-index: 9999;
  left: 0;
  top: 0;
  background: #fff;
}
</style>
