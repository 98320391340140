import { apiFactory } from "@/api/index";

export const bannerApi = apiFactory.command(`/api/banners`);
export const broadcastApi = apiFactory.command(`/api/broadcast`);

export const noticeApi = apiFactory.command(`/api/get_letters`);

export const noticeDetailsApi = apiFactory.command(
  `/api/announcementdetail_get`
);

export const homeApi = apiFactory.command(`/api/websiteInfo`);
