<template>
  <div class="vip w-fill" style="border: 1px solid red; height: 100vh">
    11113
  </div>
</template>

<script>
export default {
  name: "activity",
  components: {},
  data() {
    return {};
  },
  methods: {},
};
</script>

<style lang="less" scoped>
.list {
  .item {
    transition: all 1s;
    position: relative;
    top: 0;
    cursor: pointer;
  }
  .item:hover {
    position: relative;
    top: -4px;
  }
}
</style>
