<template>
  <div class="page w-fill flex flex-1 flex-dc">
    <div class="wrap flex flex-1">
      <div class="content flex flex-1 flex-dc">
        <!-- 1 -->
        <div class="p-20 bor-8" style="background: var(--main)">
          <p
            class="color-white fs-20 p-b-15 m-b-20"
            style="border-bottom: 1px solid var(--hight)"
          >
            推广信息
          </p>
          <div class="flex">
            <!-- code -->
            <div
              style="border: 1px solid var(--hight)"
              class="bor-8 m-r-20 p-8"
            >
              <img src="" alt="" style="width: 120px; height: 120px" />
            </div>
            <div class="flex flex-dc flex-sb flex-1">
              <div class="color-white fs-16">独家链接</div>
              <div
                style="border: 1px solid var(--hight)"
                class="bor-8 color-default p-8"
              >
                wwww.baidu.com
              </div>
              <div class="link">
                <!-- <a href="www.baidu.com" target="_blank"> -->
                <img src="@/assets/images/web-x.png" alt="" />
                <!-- </a> -->
                <img src="@/assets/images/web-tiktok.png" alt="" />
                <img src="@/assets/images/web-facebook.png" alt="" />
                <img src="@/assets/images/web-ins.png" alt="" />
                <img src="@/assets/images/web-youtobe.png" alt="" />
                <img src="@/assets/images/18.png" alt="" />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
// import LayoutFooter from "@/layout/layout-footer"
// import { UserOutlined, LockOutlined } from "@ant-design/icons-vue"

export default {
  name: "activity",
  components: {},
  data() {
    return {}
  },
  methods: {
    onShowLogin() {
      // this.$refs.login.show()
    },
  },
}
</script>

<style lang="less" scoped>
.link {
  img {
    width: 48px;
    height: 48px;
    margin-right: 15px;
  }
}
</style>
