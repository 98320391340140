<template>
  <div class="page w-fill flex flex-1 flex-dc">
    <div class="wrap flex flex-1">
      <div class="content flex flex-1 flex-dc bor-8">
        <div style="background: var(--main)" class="p-20 bor-8 m-b-15">
          <a-select
            style="width: 200px"
            placeholder="请选择时间"
            :options="time_options"
            v-model:value="form.time"
          />
        </div>
        <div style="background: var(--main)" class="p-20 bor-8">
          <a-table
            :dataSource="list"
            :loading="false"
            rowKey="id"
            :pagination="false"
          >
            <a-table-column title="会员ID" width="180px">
              <template #default="{ record }">
                <div class="text-green">{{ record.source }}</div>
                <div>{{ record.sn }}</div>
              </template>
            </a-table-column>
            <a-table-column title="加入时间" width="180px">
              <template #default="{ record }">
                <div class="text-green">{{ record.source }}</div>
                <div>{{ record.sn }}</div>
              </template>
            </a-table-column>
            <a-table-column title="佣金贡献" width="180px">
              <template #default="{ record }">
                <div class="text-green">{{ record.source }}</div>
                <div>{{ record.sn }}</div>
              </template>
            </a-table-column>
          </a-table>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
// import LayoutFooter from "@/layout/layout-footer"
// import { UserOutlined, LockOutlined } from "@ant-design/icons-vue"

export default {
  name: "activity",
  components: {},
  data() {
    return {
      form: {},
      time_options: [
        { label: "今天", value: 1 },
        { label: "本周", value: 2 },
        { label: "本月", value: 3 },
      ],
      list: [],
    }
  },
  methods: {
    onShowLogin() {
      // this.$refs.login.show()
    },
  },
}
</script>

<style lang="less" scoped></style>
