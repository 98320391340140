<template>
  <div class="modal-page login w-fill flex flex-1 flex-dc">
    <div class="modal-wrap flex flex-1">
      <div class="modal-content flex flex-1 flex-dc">
        <div class="login">
          <div class="login-wrap">
            <div class="title color-white">{{ $t(`g.登录`) }}</div>
            <div class="flex flex-dc">
              <a-input
                v-model:value="form.email"
                :placeholder="$t(`g.请输入账号`)"
                style="
                  height: 50px;
                  border: 1px solid #1be4ff;
                  background: rgba(0, 0, 0, 0);
                "
                class="bor-8"
              >
                <template #prefix>
                  <UserOutlined class="m-r-8" style="color: #60cae6" />
                </template>
              </a-input>
              <a-input
                v-model:value="form.password"
                :placeholder="$t(`g.请输入密码`)"
                type="password"
                autocomplete="off"
                style="
                  height: 50px;
                  border: 1px solid #1be4ff;
                  background: rgba(0, 0, 0, 0);
                "
                class="m-t-12 bor-8"
              >
                <template #prefix>
                  <LockOutlined class="m-r-8" style="color: #60cae6" />
                </template>
              </a-input>
              <a-input
                v-show="yzmShow"
                v-model:value="form.imgCode"
                placeholder="请输入验证码"
                style="
                  height: 50px;
                  border: 1px solid #1be4ff;
                  background: rgba(0, 0, 0, 0);
                "
                class="bor-8 m-t-12"
              >
                <template #suffix>
                  <img :src="yzm" />
                </template>
              </a-input>
              <!-- <div class="flex flex-sb flex-cc fs-18" style="height: 68px">
                <span class="color-white v-hide">
                  <a-checkbox v-model:checked="pwdCheck"></a-checkbox>
                  {{ $t(`g.记住密码`) }}
                </span>
                <span class="color-default">{{ $t(`g.忘记密码`) }}?</span>
              </div> -->
              <div
                @click="handleLogin"
                style="height: 55px"
                class="p-lr-10 bor-8 fs-16 bgs-default color-main pointer flex flex-center m-t-20"
              >
                <van-loading v-if="loading" size="14" class="m-r-4" />{{
                  $t(`g.登录`)
                }}
              </div>
              <div
                class="flex flex-sa flex-cc color-default fs-18"
                style="height: 68px"
              >
                <span class="pointer" @click="close">{{ $t(`g.游客`) }}</span>
                <span class="pointer">{{ $t(`g.注册`) }}</span>
              </div>
              <!-- <div class="flex flex-center flex-cc color-default">
                <span class="pointer">客服</span>
              </div> -->
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
// import LayoutFooter from "@/layout/layout-footer"
// import { UserOutlined, LockOutlined } from "@ant-design/icons-vue"

export default {
  name: "login",
  components: {},
  data() {
    return {
      // login
      pwdCheck: false,
      form: {
        email: "test@example.com",
        password: "password",
        remember: true,
      },
      yzm: "",
      yzmShow: false,
      loading: false,
    };
  },
  mounted() {
    this.$nextTick(() => {
      // this.getYzm()
    });
  },
  methods: {
    async handleLogin() {
      const { email, password } = this.form;
      if (!email) return this.mixMsg("请输入账号");
      if (!password) return this.mixMsg("请输入密码");

      this.loading = true;
      try {
        var res = await this.$store.dispatch("auth/login", {
          form: this.form, //formData,
        });
        this.loading = false;

        if (res) {
          this.mixMsg(this.$t(`g.登录成功`));
          this.close();
          if (this.isPc) {
            this.$router.replace("/");
          } else {
            this.$router.replace("/m");
          }
        } else {
          throw "登录错误";
        }

        this.$store.dispatch("auth/getUserInfo");
      } catch (e) {
        this.mixMsg(this.$t(`g.操作失败`));
      }

      // switch (res.status) {
      //   case "1":
      //     break;
      //   case "2":
      //     this.yzmShow = true;
      //     // this.getYzm();
      //     this.mixMsg("请输入验证码", "error");
      //     break;
      //   default:
      //     break;
      // }
    },
    async getYzm() {
      this.imgCode = "";
      let chars = [
        "0",
        "1",
        "2",
        "3",
        "4",
        "5",
        "6",
        "7",
        "8",
        "9",
        "A",
        "B",
        "C",
        "D",
        "E",
        "F",
        "G",
        "H",
        "I",
        "J",
        "K",
        "L",
        "M",
        "N",
        "O",
        "P",
        "Q",
        "R",
        "S",
        "T",
        "U",
        "V",
        "W",
        "X",
        "Y",
        "Z",
      ];
      let miyao = "";
      let i = 0;
      let b = "";
      for (i = 0; i < 82; i++) {
        b = Math.ceil(Math.random() * 35);
        miyao += chars[b];
      }
      this.miyao_ImgCode = miyao;
      var res = await this.$store.dispatch("auth/getYzm", {
        tv: miyao,
        t: "login",
      });
      this.yzm = `data:image/png;base64,${res.Data.data}`;
    },
    close() {
      this.$parent.$parent.$parent.close();
    },
  },
};
</script>
