<template>
  <div class="page page-m w-fill flex flex-1 flex-dc">
    <div class="wrap flex flex-1 p-15">
      <div class="content flex flex-1 flex-dc">
        <!-- search -->
        <div class="bor-8 p-15 flex flex-sb" style="background: var(--main)">
          <a-input
            v-model:value="value"
            placeholder="搜索游戏"
            class="bor-8"
            style="
              height: 50px;
              background: rgba(0, 0, 0, 0);
              border: 1px solid var(--hight);
            "
          />
          <div
            @click="handleSearch"
            style="width: 120px"
            class="p-lr-10 bor-8 fs-16 bgs-default color-main pointer flex flex-center m-l-15"
          >
            搜索
          </div>
        </div>
        <!-- tabs -->
        <div
          class="bor-8 p-15 flex flex-sb m-t-15"
          style="background: var(--main)"
        >
          <!-- 分类 -->
          <div class="category m-t-10 w-fill">
            <a-tabs v-model:activeKey="activeKey">
              <a-tab-pane
                :key="index"
                :tab="i.name"
                v-for="(i, index) in $store.state.game.gameType"
              ></a-tab-pane>
            </a-tabs>
            <a-spin :spinning="loading" :delay="1000">
              <div class="category__content" style="min-height: 200px">
                <div
                  class="category__item__game--list game-list flex flex-wrap"
                >
                  <div
                    class="game-list__item flex-s-0 bor-8 m-b-18 p-rel"
                    style="overflow: hidden"
                    v-for="(i, index) in $store.state.game.searchList"
                    :key="index"
                    @click="toGame(i)"
                    @scroll="handleScroll"
                  >
                    <div
                      class="name p-abs w-fill flex flex-dc flex-right t-center p-b-12"
                      style="
                        height: 50%;
                        left: 0;
                        bottom: 0;
                        z-index: 6;
                        color: #eaf2fb;
                      "
                    >
                      {{ i.name }}
                    </div>
                    <div class="p-abs ft flex flex-center">
                      <PlayCircleOutlined
                        style="color: #ffefba; font-size: 60px; opacity: 1"
                      />
                    </div>
                    <img :src="i.thumbnail" />
                  </div>
                  <!-- 占位 -->
                  <div
                    v-if="
                      !$_.isInteger($store.state.game.searchList.length / 3)
                    "
                    class="flex-s-0 bor-8 m-b-18 p-rel m-show"
                    style="width: 32%"
                  ></div>
                </div>
              </div>
            </a-spin>
            <!-- more -->
            <!--  -->
            <div class="more flex flex-center pointer">
              <div
                @click="onMore"
                v-if="
                  $store.state.game.searchList.length !==
                  $store.state.game.meta.total
                "
              >
                <span style="color: #ffefba">
                  <span class="m-r-8">More</span>
                  <DoubleRightOutlined
                    class="fs-12"
                    style="transform: rotate(90deg)"
                  />
                </span>
              </div>
              <div v-else>
                <span style="color: #ffefba">
                  <span class="m-r-8">没有更多了</span>
                </span>
              </div>
            </div>
            <!-- <i class="iconfont icon-shanchu"></i> -->
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
// import LayoutFooter from "@/layout/layout-footer"
import { PlayCircleOutlined, DoubleRightOutlined } from "@ant-design/icons-vue";

export default {
  name: "search",
  components: {
    PlayCircleOutlined,
    DoubleRightOutlined,
  },
  data() {
    return {
      value: undefined,
      tabIndex: 0,
      gameCategory: [
        {
          name: "热门",
          img: "menu-hot",
          gameList: [
            { img: "game1", best: true, love: true },
            { img: "game1" },
            { img: "game1" },
            { img: "game1" },
            { img: "game1" },
            { img: "game1" },
            { img: "game1" },
            { img: "game1" },
            { img: "game1" },
            { img: "game1" },
            { img: "game1" },
            { img: "game1" },
            { img: "game1" },
            { img: "game1" },
            { img: "game1" },
            { img: "game1" },
            { img: "game1" },
          ],
        },
        {
          name: "电子",
          img: "menu-777",
          gameList: [
            { img: "game1", best: true, love: true },
            { img: "game1" },
            { img: "game1" },
            { img: "game1" },
            { img: "game1" },
            { img: "game1" },
            { img: "game1" },
            { img: "game1" },
            { img: "game1" },
            { img: "game1" },
            { img: "game1" },
            { img: "game1" },
            { img: "game1" },
            { img: "game1" },
            { img: "game1" },
            { img: "game1" },
            { img: "game1" },
          ],
        },
      ],
      page: 1,
      typeId: undefined,
      loading: true,
    };
  },
  async created() {
    this.$nextTick(async () => {
      this.init();
    });
  },
  methods: {
    async init() {
      let res = await this.$store.dispatch("game/loadGameType");
      this.typeId = this.$store.state.game.gameType[0].id;
      this.loadList();
    },
    async loadList() {
      this.loading = true;
      await this.$store.dispatch("game/loadGameTypeList", {
        id: this.typeId,
        data: { page: this.page },
      });
      this.loading = false;
    },
    handleSearch() {
      this.mixMsg("正在开发");
    },
    async toGame(item) {
      console.log("item", item.id);
      let res = await this.$store.dispatch("game/getGameLaunch", {
        id: item.id,
      });
      if (!res.data.url) return this.mixMsg("游戏地址为空");
      setTimeout(() => {
        this.$router.push("/game-play");
      }, 0);
    },
    onMore() {
      this.page++;
      console.log("onMore");
      this.loadList();
    },
    handleScroll(event) {
      console.log("handleScroll");
      const { scrollTop, scrollHeight, clientHeight } = event.target;
      // 当内容滚动到底部时，触发
      if (scrollTop + clientHeight >= scrollHeight) {
        console.log("滑到了底部");
        // 在这里执行你需要的操作
      }
    },
  },
};
</script>

<style lang="less" scoped>
.ant-input::-webkit-input-placeholder {
  color: #98eeff !important;
  height: 48px;
}
.ant-input {
  color: #fff !important;
}

// 分类
.category {
  :deep(.ant-tabs-top-content) {
    display: none;
  }
  &__item:last-child {
    border-bottom: none;
  }
  &__item {
    border-bottom: 1px solid #1be4ff;
    padding-bottom: 12px;
    &--title {
      width: 100%;
      line-height: 24px;
      margin-bottom: 30px;
      padding-top: 30px;
      flex: 1;
      display: flex;
      align-items: center;
      font-size: 20px;
      font-weight: 400;
      color: #fff;
      img {
        width: 24px;
        margin-right: 8px;
      }
    }
  }
  .game-list {
    &__item {
      width: 138px;
      // height: 182px;
      margin-right: 18px;
      cursor: pointer;
      border-radius: 30px;
      transition: transform 0.3s ease;
      .ft {
        // display: none;
        transition: all 0.3s;
        opacity: 0;
        background: rgba(0, 0, 0, 1);
        width: 100%;
        height: 100%;
        z-index: 4;
        left: 0;
        top: 0;
      }
      .name {
        // background-image: linear-gradient(
        //   rgba(0, 0, 0, 0),
        //   rgba(0, 0, 0, 0.7),
        //   rgba(0, 0, 0, 0.9)
        // );
      }
      img {
        width: 100%;
        object-fit: contain;
        transition: transform 0.3s ease;
      }
    }
    &__item:hover {
      img {
        transform: scale(1.1);
      }
      .ft {
        opacity: 0.7;
      }
    }
    // &__item:nth-child(7n + 7) {
    //   margin-right: 0px;
    // }
  }
}

@media (max-width: 800px) {
  .category__item__game--list {
    justify-content: space-between;
  }
  .game-list {
    &__item {
      width: 32% !important;
      margin-right: 0 !important;
    }
  }
}
</style>
