<template>
  <div>
    <a-modal
      :title="title ? title : ''"
      class="uc-modal"
      :class="{ 'not-bg': notBg, 'right-close-show': !closeBottom }"
      v-model:visible="visible"
      @ok="handleOk"
      :wrapClassName="class"
    >
      <template #footer>
        <div class="flex flex-center" v-if="closeBottom">
          <CloseCircleOutlined
            @click="close"
            class="modal-close color-white pointer m-t-20"
            style="font-size: 34px"
          />
        </div>
        <div v-else></div>
      </template>
      <slot></slot>
    </a-modal>
  </div>
</template>

<script>
import { CloseCircleOutlined } from "@ant-design/icons-vue";
export default {
  name: "uc-modal",
  props: {
    title: {
      type: String,
      default: "",
    },
    notBg: {
      type: Boolean,
      default: false,
    },
    closeBottom: {
      type: Boolean,
      default: false,
    },
    class: {
      type: String,
      default: "",
    },
  },
  components: {
    CloseCircleOutlined,
  },
  data() {
    return {
      visible: false,
    };
  },
  methods: {
    show() {
      this.visible = true;
    },
    close() {
      this.visible = false;
    },
    handleOk() {},
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="less">
.not-bg {
  .ant-modal-body {
    background: rgba(0, 0, 0, 0) !important;
    border: none !important;
  }
}

.uc-modal {
  .ant-modal-close {
    display: none;
  }
  // 自定义弹窗 .uc-modal
  .ant-modal-footer {
    border-top: none !important;
    // display: none !important;
    box-shadow: none !important;
  }
  .ant-modal-content {
    background: rgba(0, 0, 0, 0);
    box-shadow: none !important;
  }
  .ant-modal-body {
    padding: 0;
    // padding: 0 30px 26px;
    background: #3bb3d6;
    // border: 1px solid #1be4ff;
    border-radius: 20px;
    overflow: hidden;
    transition: all 0.25s ease-in-out;
    box-sizing: border-box;
  }
  .ant-modal-close {
    color: #fff;
    transition: all 0.3s;
  }
  .ant-modal-close:hover {
    transform: rotate(90deg);
  }
  .modal-close {
    transition: all 0.2s;
  }
  .modal-close:hover {
    transform: rotate(90deg);
  }
}
.right-close-show {
  .ant-modal-close {
    display: block !important;
  }
}

// modal-page
.modal-page {
  overflow: hidden;
}
.modal-wrap {
  padding: 24px;
}
</style>
