<template>
  <div class="activity-details w-fill flex flex-dc">
    <div class="uc-wrap flex flex-1">
      <div class="activity-content flex flex-dc flex-center p-t-50">
        <!-- tab -->
        <div>
          1.活动期间，每天累计充值50积分，就有机会抽奖，最多5次！轮播活动最高奖金为777777
          2、本月获得的幸运金额若下个月未使用将过期。 3.
          奖励只能在iOS、Android、H5、PC端手动提现。
          4、本次活动所获得的奖金（不含主奖金）必须存入一次后方可提取。投注仅限于：
          - 老虎机：所有游戏 - 区块链活动：所有游戏 - 钓鱼：所有游戏 5.
          此活动仅限于账户持有人进行的正常操作。禁止租赁、使用插件、机器人、异号投注、互刷、仲裁、接口、协议、漏洞利用、群控等技术手段。否则，奖励将被取消或扣除，账户将被冻结甚至列入黑名单。
          6、为避免文字理解上的差异，平台保留对本次活动的最终解释权。
        </div>
      </div>
    </div>
    <LayoutFooter />
  </div>
</template>

<script>
import LayoutFooter from "@/layout/layout-footer"
import { UserOutlined, LockOutlined } from "@ant-design/icons-vue"

export default {
  name: "activity",
  components: {
    UserOutlined,
    LockOutlined,
    LayoutFooter,
  },
  data() {
    return {}
  },
  methods: {
    onShowLogin() {
      // this.$refs.login.show()
    },
  },
}
</script>

<style lang="less" scoped>
.list {
  .item {
    transition: all 1s;
    position: relative;
    top: 0;
    cursor: pointer;
  }
  .item:hover {
    position: relative;
    top: -4px;
  }
}
</style>
