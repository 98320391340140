import router from "./router";
import App from "./App.vue";
import { createApp } from "vue";

import Vant from "vant";
import "vant/lib/index.css";
import Antd from "ant-design-vue";
import "ant-design-vue/dist/antd.css";
import * as Icon from "@ant-design/icons-vue";
import "./icons/iconfont.css";
import "./styles/base.less";

// import VueAwesomeSwiper from "vue-awesome-swiper"
// import Swiper from "swiper"
// import "swiper/css"
// import "swiper/css/navigation" // 左右按钮
// import "swiper/css/pagination" // 分页小圆点

const app = createApp(App);
// components
import UcModal from "@/components/uc-modal";
import UcLang from "@/components/uc-lang";
import UcTopBar from "@/components/uc-topBar";

app.component("UcModal", UcModal);
app.component("UcLang", UcLang);
app.component("UcTopBar", UcTopBar);

// mixin
import mixinGlobal from "@/mixin/global";
import config from "@/config";
import enums from "@/utils/enums";
import formatters from "@/utils/formatters";
import i18n from "./utils/lang/i18n.js";
import _ from "lodash";
import dayjs from "dayjs";

app.mixin(mixinGlobal);
app.config.globalProperties.$config = config;
app.config.globalProperties.$enums = enums;
app.config.globalProperties.$formatters = formatters;
app.config.globalProperties.$i18n = i18n;
app.config.globalProperties.$_ = _;
app.config.globalProperties.$dayjs = dayjs;

// import Vconsole from "vconsole";
// let vConsole = new Vconsole();
// app.use(vConsole);

import store from "./store";
app.use(i18n).use(store).use(router).use(Antd).use(Vant).mount("#app");
