<template>
  <div
    class="modal-page w-fill flex flex-1 flex-dc"
    style="background: var(--main)"
  >
    <div class="modal-wrap flex flex-1 flex-dc p-0">
      <UcTopBar title="充值记录" />
      <div
        class="content flex flex-1 flex-dc m-h-100 p-15"
        style="overflow: auto"
      >
        <div class="card-list">
          <div
            class="card-item flex p-18 m-b-15"
            style="background: var(--default); border-radius: 10px"
            v-for="(i, index) in $store.state.wallet.rechargeRecords"
          >
            <div class="flex flex-dc flex-sb color-main">
              <span> {{ $t(`g.订单号`) }}：{{ i.out_trade_no }}</span>
              <span> {{ $t(`g.充值金额`) }}：{{ i.amount }}</span>
              <span>
                {{ $t(`g.订单状态`) }}：{{
                  $enums.orderStatus.filterValue(i.status)
                }}</span
              >
            </div>
          </div>
          <div class="more flex flex-center pointer m-t-20">
            <div
              @click="onMore"
              v-if="
                $store.state.wallet.rechargeRecords.length !==
                $store.state.wallet.rechargeRecordMeta.total
              "
            >
              <span style="color: #ffefba">
                <span class="m-r-8"> {{ $t(`g.更多`) }}</span>
                <DoubleRightOutlined
                  class="fs-12"
                  style="transform: rotate(90deg)"
                />
              </span>
            </div>
            <div v-else>
              <span style="color: #ffefba">
                <span class="m-r-8"> {{ $t(`g.没有更多了`) }}</span>
              </span>
            </div>
          </div>
        </div>
      </div>
      <div class="bottom-beat bg"></div>
    </div>
  </div>
</template>

<script>
export default {
  name: "xx",
  data() {
    return {
      page: 1,
    };
  },
  async created() {
    this.$nextTick(async () => {
      this.init();
    });
  },
  methods: {
    async init() {
      await this.$store.dispatch("wallet/loadRechargeRecord", {
        page: this.page,
      });
    },
    onMore() {
      this.page++;
      this.init();
    },
  },
};
</script>

<style lang="less" scoped></style>
