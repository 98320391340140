<template>
  <div class="record modal-page popup-mount w-fill flex flex-1 flex-dc">
    <div class="modal-wrap flex flex-1">
      <div class="modal-content flex flex-1 flex-dc">
        <p class="t-center fs-18 color-white">投注记录</p>
        <div
          id="record"
          class="record p-rel w-fill flex flex-dc"
          style="height: 600px"
        >
          <div class="record-wrap h-fill flex flex-dc">
            <!-- tab -->
            <div class="p-rel flex w-fill">
              <div
                class="p-abs pointer color-white"
                style="right: 0; top: 12px; z-index: 2"
                @click="show = true"
              >
                筛选<FunnelPlotOutlined />
              </div>
              <a-tabs class="w-fill">
                <a-tab-pane
                  :key="index"
                  :tab="i.name"
                  v-for="(i, index) in tabs"
                ></a-tab-pane>
              </a-tabs>
            </div>
            <!-- record-list -->
            <div class="record-list flex flex-1 flex-dc" style="overflow: auto">
              <div
                class="item flex flex-sb flex-cc p-b-6 m-b-10"
                v-for="(i, index) in [1, 2, 3, 1, 2, 3, 1, 2, 3, 1, 2, 3]"
                style="border-bottom: 1px solid #1be4ff"
              >
                <div class="flex flex-dc" style="color: #98eeff">
                  <div>晋级奖励</div>
                  <span>2024-06-25 11:58:29</span>
                </div>
                <div class="bold" style="color: #ffefba">+3</div>
              </div>
              <div class="t-center color-white m-t-20">已显示所有</div>
            </div>
            <!-- popup -->
          </div>
          <van-popup
            v-model:show="show"
            position="bottom"
            :style="{ height: '55%' }"
            :get-container="getContainer"
          >
            <div class="flex flex-dc p-15 h-fill" style="color: #96edfe">
              <!-- time -->
              <div
                class="time flex flex-sb m-b-12 p-b-12"
                style="border-bottom: 1px solid var(--sub)"
              >
                <div
                  style="border: 1px solid #1be4ff; width: 32%"
                  @click="onChangeFilter1(i, 0)"
                  v-for="(i, index) in timeFilters"
                  class="filter-item"
                  :class="i.cur ? 'cur' : ''"
                >
                  {{ i.label }}
                </div>
              </div>
              <!-- other -->
              <div
                class="other flex flex-sb flex-1 flex-wrap"
                style="overflow: auto"
              >
                <div
                  v-for="(i, index) in filters"
                  @click="onChangeFilter2(i, index)"
                  class="filter-item m-b-10"
                  style="border: 1px solid #1be4ff; width: 48%"
                  :class="i.cur ? 'cur' : ''"
                >
                  {{ i.label }}
                </div>
              </div>
              <!-- btn -->
              <div class="p-t-12">
                <div
                  @click="handleFilter"
                  style="height: 40px"
                  class="bor-8 fs-15 bgs-default color-main pointer flex flex-center"
                >
                  筛选
                </div>
              </div>
            </div>
          </van-popup>
        </div>
      </div>
    </div>
    <van-popup
      v-model:show="show"
      position="bottom"
      :style="{ height: '60%' }"
      :get-container="getContainer"
    >
      <div class="flex flex-dc p-15 h-fill" style="color: #96edfe">
        <!-- time -->
        <div
          class="time flex flex-sb m-b-12 p-b-12"
          style="border-bottom: 1px solid var(--sub)"
        >
          <div
            style="border: 1px solid #1be4ff; width: 32%"
            @click="onChangeFilter1(i, 0)"
            v-for="(i, index) in timeFilters"
            class="filter-item"
            :class="i.cur ? 'cur' : ''"
          >
            {{ i.label }}
          </div>
        </div>
        <!-- other -->
        <div class="other flex flex-sb flex-1 flex-wrap" style="overflow: auto">
          <div
            v-for="(i, index) in filters"
            @click="onChangeFilter2(i, index)"
            class="filter-item m-b-10"
            style="border: 1px solid #1be4ff; width: 48%"
            :class="i.cur ? 'cur' : ''"
          >
            {{ i.label }}
          </div>
        </div>
        <!-- btn -->
        <div class="p-t-12">
          <div
            @click="handleFilter"
            style="height: 40px"
            class="bor-8 fs-15 bgs-default color-main pointer flex flex-center"
          >
            筛选
          </div>
        </div>
      </div>
    </van-popup>
  </div>
</template>

<script>
import { FunnelPlotOutlined } from "@ant-design/icons-vue";

export default {
  name: "betting-list",
  components: {
    FunnelPlotOutlined,
  },
  data() {
    return {
      show: false,
      tabs: [
        { name: "全部" },
        { name: "已中奖" },
        { name: "未中奖" },
        { name: "等待开奖" },
      ],
      timeFilters: [{ label: "今天" }, { label: "昨天" }, { label: "七天" }],
      filters: [
        { label: "全部彩种" },
        { label: "幸运快三" },
        { label: "幸运快三" },
        { label: "幸运快三" },
        { label: "幸运快三" },
      ],
    };
  },
  mounted() {
    // this.$nextTick(() => {
    //   this.$store.dispatch("betting/loadList", {
    //     data: JSON.stringify({
    //       data_lottery_day: "1",
    //       data_lottery_num: "all",
    //       page: "1",
    //     }),
    //   })
    // })
  },
  methods: {
    async toDetails(item) {
      this.$store.commit("notice/setId", item.id);
      this.$store.dispatch("notice/getDetails", {
        data_id: this.$store.state.notice.id,
      });

      this.mixToPage("Notice-details", "noticeDetails");
    },
    handleFilter() {
      this.show = false;
    },
    showPopup() {
      this.show = true;
    },
    getContainer() {
      return document.querySelector(".popup-mount");
    },
    onChangeFilter1(item, index) {
      this.timeFilters.forEach((i) => (i.cur = false));
      item.cur = true;
      this.timeFilters.splice(0, 0);
    },
    onChangeFilter2(item, index) {
      this.filters.forEach((i) => (i.cur = false));
      item.cur = true;
      this.filters.splice(0, 0);
    },
  },
};
</script>

<style lang="less">
.record {
  .van-popup {
    position: absolute !important;
    background: #0093b1;
  }
}
.filter-item {
  padding: 3px 0;
  text-align: center;
  cursor: pointer;
}
.filter-item.cur {
  color: #fff;
  border: 1px solid #fff;
}
</style>
