import createRestfulModule from "./restful-module";
import {
  activityApi as listApi,
  activityDetailsApi as detailsApi,
} from "@/api/modules/activity";

const store = {
  state: {
    list: [],
    document: {},
    id: undefined,
  },
  // getters: {
  //   CHANNEL_OPTIONS: (state) =>
  //     useTransformOptions(state.channel, "Name", "ID"),
  // },
  mutations: {
    setList(state, data) {
      state.list = data;
    },
    setDocument(state, data) {
      state.document = data;
    },
    setId(state, data) {
      state.id = data;
    },
  },
  actions: {
    async loadList({ _, commit }, data) {
      // const res = await listApi.post(data);
      // commit("setList", res.Data);
    },
    async getDetails({ _, commit }, data) {
      let res = await detailsApi.post(data);
      // return await noticeDetailsApi.post(data)
      commit("setDocument", res.Data.announcement[0]);
    },
  },
};

export default createRestfulModule(store);
