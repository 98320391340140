<template>
  <div
    class="activity w-fill flex flex-dc page-m"
    style="height: cale(100vh - var(--bottomBarH))"
  >
    <UcTopBar title="活动中心" :back="back" />
    <div class="uc-wrap flex flex-1">
      <div class="content flex flex-dc flex-center w-fill">
        <div class="act-list p-15">
          <div
            class="item flex flex-dc bor-8 m-b-15 p-8"
            v-for="(i, index) in list"
            style="background: var(--main)"
          >
            <img
              class="w-fill"
              :src="require(`@/assets/images/${i.img}.png`)"
              style="object-fit: contain"
            />
          </div>
        </div>
        <!-- list -->
        <!-- <div class="list flex flex-dc flex-wrap">
          <div
            class="item flex flex-dc bor-8 m-b-15"
            v-for="(i, index) in list"
            style="
              width: 32%;
              background: #0093b1;
              transition: transform 0.3s ease;
            "
          >
            <img
              :src="require(`@/assets/images/${i.img}.png`)"
              style="object-fit: contain"
            />
          </div>
        </div> -->
      </div>
    </div>
    <div class="bottom-beat"></div>
    <!-- <LayoutFooter /> -->
  </div>
</template>

<script>
import LayoutFooter from "@/layout/layout-footer";
import { UserOutlined, LockOutlined } from "@ant-design/icons-vue";

export default {
  name: "activity",
  components: {
    UserOutlined,
    LockOutlined,
    LayoutFooter,
  },
  data() {
    return {
      back: false,
      list: [
        { img: "act1" },
        { img: "act2" },
        { img: "act3" },
        { img: "act4" },
      ],
    };
  },
  mounted() {
    this.$nextTick(() => {
      this.$store.dispatch("activity/loadList");
      if (this.$route.query.back) {
        this.back = true;
      }
    });
  },
  methods: {
    async toDetails(item) {
      this.$store.commit("notice/setId", item.id);
      this.$store.dispatch("notice/getDetails", {
        data_id: this.$store.state.notice.id,
      });

      this.mixToPage("Notice-details", "noticeDetails");
    },
  },
};
</script>

<style lang="less" scoped>
.list {
  .item {
    transition: all 1s;
    position: relative;
    top: 0;
    cursor: pointer;
  }
  .item:hover {
    position: relative;
    top: -4px;
  }
}
.tab {
  > div {
    cursor: pointer;
  }
  .cur {
    background: #3bb3d6;
  }
}
</style>
