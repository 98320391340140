<template>
  <div class="layout-header w-fill flex-shrink-0">
    <div class="layout-header__wrap wi-fill h-fill flex flex-cc flex-sb">
      <!-- left -->
      <div
        class="layout-header__left flex flex-center flex-shrink-0"
        style="color: #ffefba; font-size: 24px; width: 260px"
      >
        <MenuFoldOutlined
          class="m-l-15"
          v-if="$store.state.global.menuShow"
          @click="onChangeMenuShow(false)"
        />
        <MenuUnfoldOutlined
          class="m-l-15"
          v-if="!$store.state.global.menuShow"
          @click="onChangeMenuShow(true)"
        />
        <img
          @click="$router.push('/')"
          src="@/assets/images/logo.png"
          class="logo m-l-24 pointer"
          style="width: 140px"
        />
      </div>
      <!-- right -->
      <div class="layout-header__right w-fill flex flex-cc flex-sb">
        <div class="layout-header__right__wrap flex flex-cc">
          <!-- 国际时间 -->
          <!-- <div
          class="color-default t-center p-l-15 region-time m-hide"
          style="width: 110px"
        >
          06/15 08:31:00 (UTC-03:00)
        </div> -->
          <UcLang direction="bottom" class="m-hide">
            <div
              class="fs-16 flex flex-center color-default m-l-15 pointer"
              style="
                height: 40px;
                border: 2px solid #ffefba;
                border-radius: 40px;
                padding: 0 12px;
              "
            >
              <span>简体中文</span>
              <CaretDownOutlined />
            </div>
          </UcLang>

          <div
            v-if="$store.state.auth.userInfo.name"
            @click="$store.dispatch('wallet/loadBalance')"
            class="amount fs-12 flex flex-center color-default m-l-15"
            style="
              height: 40px;
              border: 2px solid #ffefba;
              border-radius: 40px;
              padding: 0 12px;
            "
          >
            <span class="bold">{{ $store.state.wallet.balance }}</span>
            <van-loading v-if="loadingAmount" />
            <ReloadOutlined
              v-else
              class="m-l-10 bold"
              @click="handleGetAmount"
            />
          </div>
          <div
            v-if="$store.state.auth.userInfo.name"
            @click="mixToPage('recharge', 'Recharge', 'modal')"
            style="height: 30px"
            class="p-lr-10 bor-8 fs-12 bgs-default color-main pointer flex flex-center m-l-15"
          >
            {{ $t(`g.充值`) }}
          </div>
          <!-- <div
            v-if="$store.state.auth.userInfo.name"
            @click="onShowTx"
            style="height: 30px; border: 2px solid #ffefba"
            class="p-lr-10 bor-8 fs-12 color-default pointer flex flex-center m-l-15"
          >
            {{ $t(`g.提现`) }}
          </div> -->
          <SearchOutlined
            class="color-default m-l-8 bold"
            v-if="$store.state.auth.userInfo.name"
            @click="$router.push('game-search')"
          />
          <!-- <div
            v-if="$store.state.auth.userInfo.name"
            @click="$router.push('game-search')"
            style="height: 40px; border: 2px solid #ffefba"
            class="p-lr-10 bor-8 fs-16 color-default pointer flex flex-center m-l-15"
          >
            <span v-if="isPc">搜索游戏</span>
          </div> -->
        </div>
        <!-- avatar -->
        <!-- v-if="$store.state.auth.userInfo.user_account_number" -->
        <a-popover
          v-if="$store.state.auth.userInfo.name"
          class="my-popup m-hide"
          placement="bottom"
          trigger="hover"
          v-model:visible="myVisible"
        >
          <template #content>
            <div style="width: 300px">
              <My />
            </div>
          </template>
          <!-- <template #title>
          <span>Title</span>
        </template> -->
          <!-- popup content -->
          <div class="flex flex-cc m-r-30 pointer">
            <img
              src="@/assets/images/avatar.png"
              style="width: 50px"
              class="m-r-10"
            />
            <div
              class="flex flex-dc color-default fs-16"
              style="font-weight: 500"
            >
              <span>{{ $store.state.auth.userInfo.name }}</span>
              <span>ID：{{ $store.state.auth.userInfo.id }}</span>
            </div>
            <CaretDownOutlined class="color-default m-l-10" />
          </div>
        </a-popover>

        <!-- 未登录 -->
        <div class="login-not flex flex-cc m-r-50" v-else>
          <div
            @click="$refs.login.show()"
            style="height: 30px"
            class="p-lr-10 bor-8 fs-16 bgs-default color-main pointer flex flex-center m-l-15"
          >
            {{ $t(`g.登录`) }}
          </div>
          <div
            @click="$refs.register.show()"
            style="height: 30px; border: 2px solid #ffefba"
            class="p-lr-10 bor-8 fs-16 color-default pointer flex flex-center m-l-15"
          >
            {{ $t(`g.注册`) }}
          </div>
          <!-- <div
            @click="logout"
            style="height: 40px; border: 2px solid #ffefba"
            class="p-lr-10 bor-8 fs-16 color-default pointer flex flex-center m-l-15"
          >
            ces
          </div> -->
        </div>
      </div>
    </div>
    <UcModal ref="login" :closeBottom="false">
      <Login />
    </UcModal>
    <UcModal ref="register" :closeBottom="false">
      <Register />
    </UcModal>
    <UcModal ref="Recharge" :class="'recharge-modal'" :closeBottom="false">
      <Recharge />
    </UcModal>
    <UcModal ref="withdrawal" :class="'withdrawal-modal'" :closeBottom="false">
      <Withdrawal />
    </UcModal>
    <UcModal ref="bankcard" :class="'bankcard-modal'" :closeBottom="false">
      <BankList />
    </UcModal>
    <UcModal ref="Cashier" :closeBottom="false">
      <Cashier />
    </UcModal>
  </div>
</template>

<script>
import Cashier from "@/views/wallet/cashier.vue";
import Login from "../views/auth/login.vue";
import Register from "../views/auth/register.vue";

import Recharge from "@/views/wallet/recharge.vue";
import Withdrawal from "../views/wallet/withdrawal.vue";
import BankList from "../views/wallet/bank-list.vue";

import My from "../views/my/index";

import {
  MenuFoldOutlined,
  MenuUnfoldOutlined,
  CaretDownOutlined,
  ReloadOutlined,
  UserOutlined,
  LockOutlined,
  SearchOutlined,
} from "@ant-design/icons-vue";

export default {
  name: "layout-header",
  components: {
    Login,
    Register,
    Recharge,
    Withdrawal,
    BankList,
    MenuFoldOutlined,
    MenuUnfoldOutlined,
    CaretDownOutlined,
    ReloadOutlined,
    SearchOutlined,
    UserOutlined,
    LockOutlined,
    My,
    Cashier,
  },
  data() {
    return {
      loginTxt: "登录",
      menuOpen: true,
      myVisible: false,
      loadingAmount: false,
    };
  },
  mounted() {
    this.$nextTick(async () => {
      await this.$store.dispatch("wallet/loadBalance", this);
    });
    // this.$refs.Cashier.show();
    // console.log(333, this.$store.state.auth.userInfo);
  },
  methods: {
    onChangeMenuShow(status) {
      this.$store.commit("global/setMenuShow", status);
    },
    async onShowTx() {
      this.$refs.withdrawal.show();
      this.$store.dispatch("wallet/loadBalance", this);
    },
    async handleGetAmount() {
      this.loadingAmount = true;
      await this.$store.dispatch("wallet/loadBalance", this);
      this.loadingAmount = false;
    },
  },
};
</script>

<style lang="less">
.layout-header {
  height: 80px;
  background: #008daa;
  border-bottom: 1px solid #1be4ff;
  width: 100%;
  overflow: hidden;
}
.ant-modal {
  .title {
    position: relative;
    height: 80px;
    line-height: 80px;
    text-align: center;
    font-weight: 700;
    font-size: 24px;
  }
  .ant-input {
    background-color: rgba(0, 0, 0, 0) !important;
    color: #fff !important;
  }
  .ant-input::-webkit-input-placeholder {
    color: #98eeff !important;
  }
  .ant-input-group {
    padding-top: 8px;
    padding-left: 8px;
    input {
      border: none;
    }
  }
}
.recharge-modal {
  .uc-modal {
    .ant-modal-body {
      background: #0093b1;
    }
  }
  .recharge-item.cur {
    background: var(--default) !important;
    color: var(--main);
  }
}
.ant-popover-inner {
  background: rgba(0, 0, 0, 0) !important;
}
.ant-popover-inner-content {
  padding: 0 !important;
  // border-radius: 8px !important;
}
</style>
