<template>
  <div class="modal-page w-fill flex flex-1 flex-dc">
    <div class="modal-wrap flex flex-1">
      <div class="modal-content flex flex-1 flex-dc">
        <div class="register">
          <div class="register-wrap">
            <div class="title color-white">{{ $t(`g.注册`) }}</div>
            <div class="flex flex-dc">
              <a-input
                v-model:value="form.email"
                :placeholder="$t(`g.请输入邮箱`)"
                style="
                  height: 50px;
                  border: 1px solid #1be4ff;
                  background: rgba(0, 0, 0, 0);
                "
                class="bor-8"
              >
                <template #prefix>
                  <UserOutlined class="m-r-8" style="color: #60cae6" />
                </template>
              </a-input>
              <a-input
                v-model:value="form.name"
                :placeholder="$t(`g.请输入用户名`)"
                style="
                  height: 50px;
                  border: 1px solid #1be4ff;
                  background: rgba(0, 0, 0, 0);
                "
                class="bor-8 m-t-12"
              >
                <template #prefix>
                  <UserOutlined class="m-r-8" style="color: #60cae6" />
                </template>
              </a-input>
              <a-input
                v-model:value="form.password"
                :placeholder="$t(`g.请输入密码`)"
                type="password"
                autocomplete="off"
                style="
                  height: 50px;
                  border: 1px solid #1be4ff;
                  background: rgba(0, 0, 0, 0);
                "
                class="m-t-12 bor-8"
              >
                <template #prefix>
                  <LockOutlined class="m-r-8" style="color: #60cae6" />
                </template>
              </a-input>
              <a-input
                v-model:value="form.password_confirmation"
                :placeholder="$t(`g.请再次输入密码`)"
                type="password"
                autocomplete="off"
                style="
                  height: 50px;
                  border: 1px solid #1be4ff;
                  background: rgba(0, 0, 0, 0);
                "
                class="m-t-12 bor-8"
              >
                <template #prefix>
                  <LockOutlined class="m-r-8" style="color: #60cae6" />
                </template>
              </a-input>
              <!-- <a-input
                v-model:value="form.shuihao"
                addon-before="CPF"
                placeholder="请输入税号"
                style="
                  height: 50px;
                  border: 1px solid #1be4ff;
                  background: rgba(0, 0, 0, 0);
                "
                class="m-t-12 bor-8"
              >
              </a-input> -->
              <div class="flex flex-sb flex-cc fs-18" style="height: 68px">
                <span class="color-white">
                  <a-checkbox v-model:checked="form.agreement"></a-checkbox>
                  {{ $t(`g.我已年满十八岁，已阅读并同意`)
                  }}<span class="color-default"
                    >《{{ $t(`g.用户协议`) }}》</span
                  >
                </span>
              </div>
              <div
                @click="handleRegister"
                style="height: 55px"
                class="p-lr-10 bor-8 fs-16 bgs-default color-main pointer flex flex-center"
              >
                {{ $t(`g.注册`) }}
              </div>
              <div
                class="flex flex-sa flex-cc color-default fs-18"
                style="height: 68px"
              >
                <span class="pointer">{{ $t(`g.游客`) }}</span>
                <span class="pointer">{{ $t(`g.登录`) }}</span>
              </div>
              <!-- <div class="flex flex-center flex-cc color-default">
                <span class="pointer">客服</span>
              </div> -->
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { message } from "ant-design-vue";
// import LayoutFooter from "@/layout/layout-footer"
// import { UserOutlined, LockOutlined } from "@ant-design/icons-vue"
import { toRaw } from "vue";
export default {
  name: "register",
  components: {},
  data() {
    return {
      // register
      form: {
        // email: undefined,
        // password: undefined,
        // password_confirmation: undefined,
        // name: undefined,
        // agreement: false,

        email: "857298877@gmail.com",
        password: "333333333",
        password_confirmation: "333333333",
        name: "333333333",
        agreement: true,
      },
    };
  },
  methods: {
    async handleRegister() {
      const { email, password, password_confirmation, name, agreement } = toRaw(
        this.form
      );
      console.log("this.form", this.form, this.form.email);
      if (!email) return this.mixMsg("请输入邮箱");
      if (!password) return this.mixMsg("请输入密码");
      if (!password_confirmation) return this.mixMsg("请再次输入密码");
      if (!name) return this.mixMsg("请输入用户名");
      if (!agreement) return this.mixMsg("请同意用户协议");

      try {
        var res = await this.$store.dispatch("auth/register", {
          form: this.form, //formData,
        });
        message.success("注册成功");
        this.close();
        // setTimeout(() => {
        //   that.$router.back();
        // }, 1500);
      } catch (e) {
        message.error("请求失败");
      }

      // this.$refs.login.show()
      // var res = await this.$store.dispatch("auth/login", {
      //   form: this.form, //formData,
      // });
    },
    close() {
      this.$parent.$parent.$parent.close();
    },
  },
};
</script>

<style lang="less" scoped></style>
