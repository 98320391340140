<template>
  <div class="notice modal-page popup-mount w-fill flex flex-1 flex-dc">
    <div class="modal-wrap flex flex-1">
      <div class="modal-content flex flex-1 flex-dc">
        <p class="t-center fs-18 color-white">通知</p>
        <div
          id="record"
          class="record p-rel w-fill flex flex-dc"
          style="height: 600px"
        >
          <div class="record-wrap h-fill flex flex-dc">
            <!-- tab -->
            <div class="p-rel flex w-fill">
              <a-tabs class="w-fill">
                <a-tab-pane
                  :key="index"
                  :tab="i.name"
                  v-for="(i, index) in tabs"
                ></a-tab-pane>
              </a-tabs>
            </div>
            <!-- record-list -->
            <div class="record-list flex flex-1 flex-dc" style="overflow: auto">
              <div
                class="item flex flex-sb flex-cc p-b-6 m-b-10 pointer"
                @click="toDetails(i)"
                v-for="(i, index) in $store.state.notice.list"
                style="border-bottom: 1px solid #1be4ff"
              >
                <div class="flex flex-dc" style="color: #98eeff">
                  <div>{{ i.title }}</div>
                  <span class="text-ellipsis">{{
                    $dayjs(i.time_year * 1000).format("YYYY-MM-DD HH:mm:ss")
                  }}</span>
                </div>
                <div class="bold" style="color: #ffefba">
                  <RightOutlined />
                </div>
              </div>
              <div class="t-center color-white m-t-20">已显示所有</div>
            </div>
            <!-- popup -->
          </div>
        </div>
      </div>
    </div>
    <UcModal ref="Details" :closeBottom="false">
      <Details />
    </UcModal>
  </div>
</template>

<script>
import { FunnelPlotOutlined, RightOutlined } from "@ant-design/icons-vue"
import Details from "./details"

export default {
  name: "record",
  components: {
    FunnelPlotOutlined,
    RightOutlined,
    Details,
  },
  data() {
    return {
      show: false,
      tabs: [{ name: "公告" }, { name: "消息" }],
    }
  },
  mounted() {
    this.$nextTick(() => {
      this.$store.dispatch("gameRecord/loadList", { type: "1" })
    })
  },
  methods: {
    async toDetails(item) {
      this.$store.commit("gameRecord/setId", item.id)
      this.$store.dispatch("gameRecord/getDetails", {
        data_id: item.id,
      })

      this.mixToPage("game-record-details", "Details")
    },
  },
}
</script>

<style lang="less"></style>
