<template>
  <div style="background: var(--sub)" class="fs-12 color-white">
    <UcTopBar title="邀请有礼" :back="back" />
    <div class="wrap p-12">
      <!-- 0 -->
      <div class="flex flex-sb frame p-14 m-b-10">
        <div
          class="flex flex-dc p-6 m-r-10"
          style="border-radius: 4px; border: 1px solid var(--hight)"
        >
          <div id="qrcode"></div>
        </div>
        <div class="flex flex-dc flex-sb flex-1">
          <div class="flex flex-dc">
            <div class="flex flex-sb m-b-8">
              <span> {{ $t(`g.我的链接`) }}</span>
              <CopyOutlined class="fs-18" @click="onCopy" />
            </div>
            <div
              style="border: 1px solid var(--hight); border-radius: 4px"
              class="p-6"
            >
              <span
                class="copy-txt"
                :data-clipboard-text="$store.state.agent.info.link"
                >{{ $store.state.agent.info.link }}</span
              >
            </div>
          </div>
          <div class="stats flex flex-1 flex-sb flex-dc m-t-10">
            <div>
              {{ $t(`g.推荐用户`) }}：<span class="color-default">{{
                $store.state.agent.info.referred_users_count
              }}</span>
            </div>
            <div>
              {{ $t(`g.付费用户`) }}：<span class="color-default">{{
                $store.state.agent.info.referred_paid_users_count
              }}</span>
            </div>
            <div>
              {{ $t(`g.支付总额`) }}：<span class="color-default">{{
                $store.state.agent.info.referred_users_paid_total
              }}</span>
            </div>
          </div>
        </div>
      </div>
      <!-- 1 -->
      <div
        class="flex flex-cc flex-sa frame"
        style="
          position: relative;
          width: 100%;
          padding: 20px;
          margin-bottom: 20px;
        "
      >
        <div class="p-rel m-r-20" style="height: 60px">
          <div class="letter p-abs flex flex-center">A</div>
          <img
            src="https://tascaslot.com/img/avt0.dd0fe52d.png"
            alt=""
            style="
              width: 60px;
              height: 60px;
              border: 1px solid var(--hight);
              border-radius: 50%;
            "
          />
        </div>
        <div class="flex flex-dc">
          <div class="color-white">
            {{ $t(`g.总业绩`) }} <span style="color: #d6b839">225</span>
          </div>
          <div class="color-white">
            {{ $t(`g.总佣金`) }} <span style="color: #d6b839">225</span>
          </div>
          <div class="color-white">
            {{ $t(`g.直属业绩`) }} <span style="color: #d6b839">225</span>
          </div>
        </div>
        <div class="flex flex-dc">
          <div class="color-white">
            {{ $t(`g.返佣3%直属佣金`) }} <span style="color: #d6b839">225</span>
          </div>
          <div class="color-white">
            {{ $t(`g.其他业绩`) }} <span style="color: #d6b839">225</span>
          </div>
          <div class="color-white">
            {{ $t(`g.其他佣金`) }} <span style="color: #d6b839">225</span>
          </div>
        </div>
      </div>
      <div class="wrap p-12">
        <!-- 1 -->
        <div
          class="flex flex-cc flex-sa frame"
          style="
            position: relative;
            width: 100%;
            padding: 20px;
            margin-bottom: 20px;
          "
        >
          <div class="p-rel m-r-20" style="height: 60px">
            <div class="letter p-abs flex flex-center">A</div>
            <img
              src="https://tascaslot.com/img/avt0.dd0fe52d.png"
              alt=""
              style="
                width: 60px;
                height: 60px;
                border: 1px solid var(--hight);
                border-radius: 50%;
              "
            />
          </div>
          <div class="flex flex-dc">
            <div class="color-white">
              {{ $t(`g.总业绩`) }} <span style="color: #d6b839">225</span>
            </div>
            <div class="color-white">
              {{ $t(`g.总佣金`) }} <span style="color: #d6b839">225</span>
            </div>
            <div class="color-white">
              {{ $t(`g.直属业绩`) }} <span style="color: #d6b839">225</span>
            </div>
          </div>
          <div class="flex flex-dc">
            <div class="color-white">
              {{ $t(`g.返佣3%直属佣金`) }}
              <span style="color: #d6b839">225</span>
            </div>
            <div class="color-white">
              {{ $t(`g.其他业绩`) }} <span style="color: #d6b839">225</span>
            </div>
            <div class="color-white">
              {{ $t(`g.其他佣金`) }} <span style="color: #d6b839">225</span>
            </div>
          </div>
        </div>

        <div style="max-width: 426px; margin: 0 auto" class="p-rel p-t-18">
          <!-- 2 -->
          <img
            class="p-abs w-fill"
            style="left: 0; top: 0; z-index: 1; line-height: 1"
            src="@/assets/images/agent-jiantou.svg"
          />

          <div class="flex flex-sb p-rel" style="z-index: 2">
            <div class="frame flex flex-dc flex-center p-lr-4">
              C1 {{ $t(`g.贡献给`) }}A
              <div style="color: var(--orange)">20</div>
              {{ $t(`g.来自级差`) }}2%
            </div>
            <div class="frame flex flex-dc flex-center p-lr-4">
              C3 {{ $t(`g.贡献给`) }}A
              <div style="color: var(--orange)">0</div>
              {{ $t(`g.来自级差`) }}2%
            </div>
          </div>
          <!-- 3 -->
          <div class="flex flex-sa p-t-10 p-rel" style="z-index: 2">
            <div class="frame flex flex-center p-lr-4">
              B1 {{ $t(`g.贡献佣金`) }}
              <div style="color: var(--orange)">15</div>
            </div>
            <div class="frame flex flex-center p-lr-4">
              B1 {{ $t(`g.贡献佣金`) }}
              <div style="color: var(--orange)">90</div>
            </div>
            <div class="frame flex flex-center p-lr-4">
              B1 {{ $t(`g.贡献佣金`) }}
              <div style="color: var(--orange)">60</div>
            </div>
          </div>
          <!-- 4 -->
          <div class="flex flex-sa p-t-50 p-rel" style="z-index: 2">
            <div
              class="frame flex flex-dc flex-center flex-right p-lr-4 p-rel"
              style="height: 52px"
            >
              <div class="img-box p-abs" style="top: -34px">
                <div class="p-rel" style="height: 60px">
                  <div class="letter p-abs flex flex-center">B1</div>
                  <img
                    src="https://tascaslot.com/img/avt0.dd0fe52d.png"
                    alt=""
                    style="
                      width: 60px;
                      height: 60px;
                      border: 1px solid var(--hight);
                      border-radius: 50%;
                    "
                  />
                </div>
              </div>
              <span> {{ $t(`g.有效投注`) }} 500</span>
            </div>
            <div
              class="frame flex flex-dc flex-center flex-right p-lr-4 p-rel"
              style="height: 52px"
            >
              <div class="img-box p-abs" style="top: -34px">
                <div class="p-rel" style="height: 60px">
                  <div class="letter p-abs flex flex-center">B2</div>
                  <img
                    src="https://tascaslot.com/img/avt0.dd0fe52d.png"
                    alt=""
                    style="
                      width: 60px;
                      height: 60px;
                      border: 1px solid var(--hight);
                      border-radius: 50%;
                    "
                  />
                </div>
              </div>
              <span> {{ $t(`g.有效投注`) }} 3000</span>
            </div>
            <div
              class="frame flex flex-dc flex-center flex-right p-lr-4 p-rel"
              style="height: 52px"
            >
              <div class="img-box p-abs" style="top: -34px">
                <div class="p-rel" style="height: 60px">
                  <div class="letter p-abs flex flex-center">B3</div>
                  <img
                    src="https://tascaslot.com/img/avt0.dd0fe52d.png"
                    alt=""
                    style="
                      width: 60px;
                      height: 60px;
                      border: 1px solid var(--hight);
                      border-radius: 50%;
                    "
                  />
                </div>
              </div>
              <span> {{ $t(`g.有效投注`) }} 2000</span>
            </div>
          </div>
          <!-- 5 -->
          <div class="flex flex-right p-t-10 p-rel" style="z-index: 2">
            <div
              class="frame flex flex-dc flex-center p-lr-4"
              style="margin-right: 100px"
            >
              <div>
                C2 {{ $t(`g.贡献给`) }}A
                <span style="color: var(--orange)">40</span>
              </div>
              <div>{{ $t(`g.来自级差`) }}2%</div>
            </div>
          </div>
          <!-- 6 -->
          <div class="flex flex-sa p-t-24 p-rel" style="z-index: 2">
            <div class="frame flex flex-center p-lr-4">
              C1 {{ $t(`g.贡献佣金`) }}
              <div style="color: var(--orange)">20</div>
            </div>
            <div class="frame flex flex-center p-lr-4">
              C2 {{ $t(`g.贡献佣金`) }}
              <div style="color: var(--orange)">40</div>
            </div>
            <div class="frame flex flex-center p-lr-4">
              C3 {{ $t(`g.贡献佣金`) }}
              <div style="color: var(--orange)">0</div>
            </div>
          </div>
          <!-- 7 -->
          <div class="flex flex-sa p-t-50 p-rel" style="z-index: 2">
            <div
              class="frame flex flex-dc flex-center flex-right p-lr-4 p-rel"
              style="height: 55px"
            >
              <div class="img-box p-abs" style="top: -34px">
                <div class="p-rel" style="height: 60px">
                  <div class="letter p-abs flex flex-center">C1</div>
                  <img
                    src="https://tascaslot.com/img/avt0.dd0fe52d.png"
                    alt=""
                    style="
                      width: 60px;
                      height: 60px;
                      border: 1px solid var(--hight);
                      border-radius: 50%;
                    "
                  />
                </div>
              </div>
              <span> {{ $t(`g.有效投注`) }} 1000</span>
            </div>
            <div
              class="frame flex flex-dc flex-center flex-right p-lr-4 p-rel"
              style="height: 55px"
            >
              <div class="img-box p-abs" style="top: -34px">
                <div class="p-rel" style="height: 60px">
                  <div class="letter p-abs flex flex-center">C2</div>
                  <img
                    src="https://tascaslot.com/img/avt0.dd0fe52d.png"
                    alt=""
                    style="
                      width: 60px;
                      height: 60px;
                      border: 1px solid var(--hight);
                      border-radius: 50%;
                    "
                  />
                </div>
              </div>
              <span> {{ $t(`g.有效投注`) }} 2000</span>
            </div>
            <div
              class="frame flex flex-dc flex-center flex-right p-lr-4 p-rel"
              style="height: 55px"
            >
              <div class="img-box p-abs" style="top: -34px">
                <div class="p-rel" style="height: 60px">
                  <div class="letter p-abs flex flex-center">C3</div>
                  <img
                    src="https://tascaslot.com/img/avt0.dd0fe52d.png"
                    alt=""
                    style="
                      width: 60px;
                      height: 60px;
                      border: 1px solid var(--hight);
                      border-radius: 50%;
                    "
                  />
                </div>
              </div>
              <span> {{ $t(`g.有效投注`) }} 20000</span>
            </div>
          </div>
        </div>

        <!-- DESC -->
        <div class="frame p-10 m-t-20">
          Um exemplo é o seguinte:Suponha que as apostas efetivas atuais de 0 a
          10.000 receberão uma comissão de 100 (ou seja, 1%) para cada 10.000, e
          o apostas efetivas acima de 10.000 receberão uma comissão de 300 para
          cada 10.000. (ou seja, 3%), A foi o primeiro a descobrir oportunidades
          de negócios neste site e imediatamente desenvolveu B1, B2 e B3. B1
          desenvolveu ainda mais C1 e C2. B2 desenvolveu sem subordinados, e a
          B3 desenvolveu o relativamente poderoso C3. No segundo dia, a aposta
          efetiva de B1 é 500, a aposta efetiva de B2 é 3.000, a aposta efetiva
          de B3 é 2.000, a aposta efetiva de C1 é 1.000, a aposta efetiva de C2
          é 2.000 e a aposta efetiva de C3 é de até 20.000. Então o método de
          cálculo da renda entre eles é o seguinte: 1. Comissão de B1
          (contribuições diretas de C1 e C2) = (1000 + 2000) * 1% = 30 2.
          Comissão de B2 (sem subordinados) = (0+0) * 1% = 0 3. Comissão B3
          (contribuição direta de C3) = 20.000 * 3% = 600 4. Além das
          contribuições dos subordinados diretos B1, B2 e B3, a comissão de A
          também advém das contribuições dos demais subordinados C1, C2 e C3,
          conforme segue: ( 1 )Comissão direta de A(contribuições diretas de B1,
          B2 e B3) = (500+3000+2000) * 3% = 165 ( 2) Outras comissões de A(das
          contribuições C1, C2)=(1000+2000) * 2%= 60 (3)Comissão total de A
          (direto + outro) = 165+60 = 225 5. Resumo: (1) Equipe direta:
          refere-se aos subordinados desenvolvidos diretamente por A, ou seja, o
          primeiro nível de relacionamento com A, denominados coletivamente como
          equipe direta. (2) Outras equipes: Refere-se àquelas que são
          desenvolvidas por subordinados de A. Possuem relacionamento de segundo
          nível com A ou superior, ou seja, subordinados de subordinados , e os
          subordinados dos subordinados.. etc., coletivamente referidos como
          outras equipes; como esse modelo de agência pode desenvolver
          subordinados ilimitados, para conveniência da explicação, este artigo
          toma apenas a estrutura de 2 níveis como exemplo. (3) Descrição de A:
          O desempenho direto de A é 5.500 e o outro desempenho é 20.000 (devido
          ao poder de C3). O desempenho total é 28.500 e a comissão
          correspondente a taxa é de 3%. Como B1 tem um desempenho total de
          3.000 e desfruta de um desconto de apenas 1%, enquanto A tem um
          desempenho total de 28.500 e desfruta de uma taxa de desconto de 3%,
          então haverá uma diferença de desconto entre A e B1. A diferença é: 3%
          -1% =2%, essa diferença é a parte contribuída por C1 e C2 para A,
          então C1 e C2 contribuem para A: (1000+2000)* 2%=60, não há diferença
          extrema entre A e B3, então C3 contribui para A A comissão de
          contribuição é 0. (4) Descrição de B1: B1 tem subordinados C1 e C2.
          Como o desempenho direto é 3.000, o índice de desconto correspondente
          é de 1%. (5) Explicação B2 : B2 pode ser preguiçoso e não se
          beneficiará se não desenvolver seus subordinados. (6) Explicação B3:
          Embora B3 tenha aderido relativamente tarde e seja subordinado de A,
          seu subordinado C3 é muito poderoso e tem um desempenho direto de
          20.000, permitindo que B3 diretamente desfrutar de comissões mais
          elevadas.A proporção é de 3%. (7) Resumo das regras: Não importa
          quando você ingressa, de quem você é subordinado, não importa em que
          nível você está, sua renda nunca será afetada e você não sofre mais as
          perdas dos subordinados dos outros., o desenvolvimento não é restrito.
          Este é um modelo de agência absolutamente justo e imparcial, e ninguém
          estará sempre por baixo só porque entrou tarde.
        </div>
        <div class="bottom-beat"></div>
      </div>
    </div>
  </div>
</template>
<script>
import QRCode from "qrcodejs2";
import { CopyOutlined } from "@ant-design/icons-vue";
import useClipboard from "vue-clipboard3";
const { toClipboard } = useClipboard();

export default {
  name: "share",
  components: { CopyOutlined },
  data() {
    return {
      form: {},
      time_options: [
        { label: "今天", value: 1 },
        { label: "本周", value: 2 },
        { label: "本月", value: 3 },
      ],
      list: [],
      back: false,
      loading:false,
    };
  },
  async created() {
    this.loading = true
    await this.$store.dispatch("agent/loadAgent");
    this.loading = false

    // 创建二维码，'qrcode'是显示二维码的DOM元素的ID，'your content'是二维码包含的内容
    new QRCode(document.getElementById("qrcode"), {
      text: this.$store.state.agent.info.link,
      width: 128,
      height: 128,
      colorDark: "#000000",
      colorLight: "#ffffff",
      correctLevel: QRCode.CorrectLevel.H,
    });
  },
  mounted() {
    this.$nextTick(() => {
      if (this.$route.query.back) {
        this.back = true;
      }
    });
  },
  methods: {
    async onCopy() {
      try {
        await toClipboard(this.$store.state.agent.info.link);
        this.mixMsg(this.$t(`g.操作成功`));
      } catch (e) {
        console.log(e);
        this.mixMsg(this.$t(`g.操作失败`));
      }
    },
  },
};
</script>

<style lang="less" scoped>
.frame {
  border-radius: 8px;
  background-color: var(--main);
  border: 1px solid var(--hight);
}
.letter {
  width: 20px;
  height: 20px;
  background: var(--main);
  border-radius: 50%;
  right: 4px;
  bottom: 4px;
  color: var(--default);
}
.box {
  overflow: scroll;
  width: 100%;
  height: 100%;

  .box-item {
    background-color: #0b518b;
    padding: 2px;
    margin: 10px 10px 0;
    border-radius: 5px;

    .box-item-title {
      display: flex;
      justify-content: flex-start;
      padding: 0 10px;
      width: 100%;
      // border-bottom: 1px solid var(--theme-color-line);
      box-shadow: none;
      font-size: 12px;

      .item-title-left {
        cursor: pointer;
        height: 100%;
        position: relative;
        margin-right: 10px;

        span {
          width: 65px;
          height: 35px;
          display: inline-block;

          img {
            width: 100%;
            height: auto;
          }
        }

        span:nth-child(2) {
          width: 12px;
          height: 12px;
          display: flex;
          justify-content: center;
          align-items: center;
          // background-color: var(--theme-primary-color);
          border-radius: 50%;
          // color: var(--theme-primary-font-color);
          cursor: pointer;
          position: absolute;
          top: 5px;
          right: 0px;
        }
      }

      .item-title-right {
        flex-grow: 1;
        padding: 10px 0;

        .title-right-content {
          align-items: center;
          color: #fff;
          display: flex;
          font-size: 12px;
          justify-content: space-between;
          padding: 0;

          .content-item {
            display: flex;
            flex-grow: 1;
            align-items: center;
            color: #2f8ddc;

            span {
              margin-right: 5px;
            }

            button {
              width: 40px;
              font-size: 12px;
            }

            .ant-btn-default {
              margin-right: 10px;
              width: 50px !important;
            }
          }
        }

        .right-content-footer {
          align-items: flex-start;
          color: #fff;
          display: flex;
          flex-wrap: wrap;
          font-size: 12px;
          justify-content: space-between;
          margin-top: 10px;
          padding: 0;
          width: 100%;

          .content-footer {
            align-items: center;
            color: #2f8ddc;
            display: flex;

            span:nth-child(2) {
              color: #fff;
            }
          }
        }
      }
    }

    .box-item-content {
      align-items: flex-start;
      box-sizing: border-box;
      display: flex;
      flex-wrap: wrap;
      justify-content: space-between;
      padding: 10px 10px 0;

      .content-left {
        background: #fff;
        // border: 1px solid var(--theme-color-line);
        border-radius: 5px 5px 10px 10px;
        box-sizing: content-box;
        display: flex;
        flex-direction: column;
        justify-content: flex-end;
        min-height: 65px;
        position: relative;
        width: 77px;
        height: 100px;

        img {
          width: 65px;
          height: 65px;
          margin: 5px;
        }

        .ant-btn-link {
          background-color: #3ccfff;
          border-radius: 0 0 5px 5px;
          border-top-left-radius: 0 !important;
          border-top-right-radius: 0 !important;
          color: #fff;
          font-size: 14px;
          height: auto;
          min-height: 24px;
          padding-bottom: 2px;
          white-space: pre-wrap;
          width: 77px;
          word-break: break-word;
        }
      }

      .content-right {
        width: 240px;

        .content-right-item {
          color: #2f8ddc;
          display: flex;
          font-size: 12px;
          height: 15px;
          justify-content: space-between;
          margin-bottom: 5px;
        }

        .content-dropdown:hover {
          // border-color: var(--theme-ant-primary-color-13);
        }

        .content-dropdown {
          width: 100%;
          height: 100%;
          position: relative;
          border-width: 1px;
          border-style: solid;
          // border-color: var(--theme-color-line);
          border-radius: 5px;
          transition: all 0.3s ease;

          .dropdown-icon {
            position: absolute;
            right: 5px;
            top: 50%;
            transform: translateY(-50%);
          }

          .dropdown-change {
            font-size: 12px;
            color: #66deff;
            top: -15px;
          }

          :deep(.van-popup--top) {
            width: 240px;
            left: 113px;
            background: #0b518b;
            // border: 1px solid var(--theme-color-line);
            border-radius: 5px;
            margin-top: 3px;
          }

          :deep(.van-cell) {
            background: transparent;
            color: #2f8ddc;
            font-size: 12px;
            line-height: 1.2;
          }

          :deep(.van-cell__value) {
            display: none;
          }

          :deep(.van-dropdown-menu__title):after {
            display: none;
          }

          :deep(.van-ellipsis) {
            overflow: visible;
            white-space: normal;
            text-overflow: clip;
            font-size: 10px;
            line-height: 1.2;
            padding: 5px;
            color: #9dc5e6;
          }

          :deep(.van-dropdown-menu__bar) {
            height: 40px;
            background: transparent;
            box-shadow: none;
            // border:1px solid var(--theme-color-line);
            // border-radius: 5px;
          }
        }
      }
    }
  }
}
</style>
