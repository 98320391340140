import { message } from "ant-design-vue";
import { Toast } from "vant";

export default {
  data() {
    return {
      isPc: false,
      mixTrigger: "click",
    };
  },
  methods: {
    mixinMethod() {
      console.log("This is a method from mixin");
    },
    mixMsg(txt, type = "info") {
      if (this.isPc) {
        message[type](txt);
      } else {
        Toast({
          message: txt,
        });
      }
    },
    mixToPage(url, refName, type) {
      if (type === "modal") {
        this.$refs[refName].show();
      } else {
        if (this.isPc) {
          this.$refs[refName].show();
        } else {
          this.$router.push(url);
        }
      }
    },
  },
};
