<template>
  <div class="page w-fill flex flex-1 flex-dc">
    <UcTopBar title="充值" />
    <div class="flex flex-1">
      <iframe :src="$store.state.wallet.payUrl"></iframe>
    </div>
  </div>
</template>

<script>
export default {
  name: "payUrl",
  components: {},
  data() {
    return {};
  },
  async created() {},
  methods: {},
};
</script>

<style lang="less" scoped>
iframe {
  border: none;
  height: 100%;
  width: 100%;
  // position: fixed;
  // z-index: 9999;
  left: 0;
  top: 0;
  background: #fff;
}
</style>
