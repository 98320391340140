import createRestfulModule from "./restful-module";
import {
  gameApi,
  gameTypeApi,
  gameTypeSearchApi,
  gameLaunchApi,
  gameBettingRecordApi,
} from "@/api/modules/game";
import { useTransformOptions } from "@/utils/functions";

const store = {
  state: {
    // game
    game: [],
    document: {
      name: "",
    },
    id: undefined,

    // search
    gameType: [],
    searchList: [],
    playUrl: "",
    meta: {},
    bettingRecords: [],
    bettingRecordMeta: {},

    // hot
    hots: [],
    hotMeta: {},

    // loading
    loading: false,
    gameCur: {},
  },
  getters: {
    GAME_TYPE_OPTIONS: (state) =>
      useTransformOptions(state.gameType, "name", "id", ["code"]),
  },
  mutations: {
    setState(state, data) {
      state[data.attr] = data.state;
    },
  },
  actions: {
    async loadGameType({ _, commit }) {
      const res = await gameTypeApi.get({});
      commit("setState", { attr: "gameType", state: res.data.data });
    },
    async loadGameTypeList({ _, commit, state }, { id, data }) {
      commit("setState", { attr: "loading", state: true });
      const res = await gameTypeSearchApi(id).get(data);
      commit("setState", { attr: "loading", state: false });
      let l = state.searchList.concat(res.data.data);
      l.forEach((i) => {
        i.thumbnail;
      });
      commit("setState", { attr: "searchList", state: l });
      commit("setState", { attr: "meta", state: res.data.meta });
    },
    async loadGameHot({ _, commit, state }, { id = 1, data }) {
      const res = await gameTypeSearchApi(id).get({ ...data, popular: true });
      let l = state.hots.concat(res.data.data);
      commit("setState", { attr: "hots", state: l });
      commit("setState", { attr: "hotMeta", state: res.data.meta });
    },
    async getGameLaunch({ _, commit }, { id }) {
      commit("setState", { attr: "loading", state: true });
      let res = await gameLaunchApi(id).post();
      commit("setState", { attr: "loading", state: false });
      return res;
    },

    async loadBettingRecord({ _, commit, state }, data) {
      try {
        commit("setState", { attr: "loading", state: true });
        const res = await gameBettingRecordApi.get(data);
        res.data.data.forEach((i) => {
          i.profit = i.award_amount - i.amount;
        });
        let list = state.bettingRecords.concat(res.data.data);
        commit("setState", { attr: "bettingRecords", state: list });
        commit("setState", { attr: "bettingRecordMeta", state: res.data.meta });
        commit("setState", { attr: "loading", state: false });
      } catch (e) {
        console.log("e", e);
      }
    },
  },
};

export default createRestfulModule(store);
