<template>
  <div class="modal-page w-fill flex flex-1 flex-dc">
    <UcTopBar title="常见问题" />
    <div class="modal-wrap flex flex-1 p-0">
      <div class="modal-content flex flex-1 flex-dc">
        <div class="color-white p-15">
          <!-- <p class="t-center fs-18">常见问题</p> -->
          <p class="pointer" @click="$refs.about.show()">{{ $t(`g.关于我们`) }}</p>
          <p class="pointer" @click="$refs.intro.show()">{{ $t(`g.介绍`) }}</p>
          <p class="pointer" @click="$refs.online.show()">{{ $t(`g.在线游戏`) }}</p>
          <p class="pointer" @click="$refs.award.show()">{{ $t(`g.获奖者`) }}</p>
          <p class="pointer" @click="$refs.contact.show()">{{ $t(`g.联系我们`) }}</p>
        </div>
      </div>
    </div>
    <UcModal ref="about" :closeBottom="false">
      <div class="color-white p-15">
        <p class="t-center p-t-15 fs-18">{{ $t(`g.关于我们`) }}</p>
        该公司成立于 1998
        年，为玩家提供各种在线赌场游戏，包括老虎机、桌面游戏、视频扑克、刮刮卡和真人赌场游戏，可在多种不同的设备上玩。该赌场已获得独立测试机构的认证。玩家可以在采用最新
        SSL
        加密技术的安全环境中享受游戏。赌场存款和取款有多种银行选项，包括主要信用卡和借记卡、网络钱包和预付费解决方案。专业支持团队随时准备通过电子邮件和实时聊天提供
        24/7 协助
      </div>
    </UcModal>
    <UcModal ref="intro" :closeBottom="false">
      <div class="color-white p-20">
        <p class="t-center fs-18">介绍</p>
        这是您在线赌场体验的开始。当您登录时，大厅为您提供了一种简单的方式来了解每日促销、旋转奖金轮或浏览新游戏。您会看到不同的游戏类别，例如老虎机、真人荷官游戏和累积奖金。查看网站上的所有功能。
      </div>
    </UcModal>
    <UcModal ref="online" :closeBottom="false">
      <div class="color-white p-20">
        <p class="t-center fs-18">在线游戏</p>
        您可以玩数百种真钱在线赌场游戏，包括经典的三卷轴和现代的五卷轴老虎机，以及大量的游戏内奖金功能，您还可以尝试二十一点和轮盘赌等桌面游戏。您还可以选择在真人荷官面前玩这些经典游戏，以及其他真人荷官游戏，如闪电轮盘和捕梦网。我们的赌场大厅还提供视频扑克、刮刮卡、在线宾果游戏和其他休闲游戏。
      </div>
    </UcModal>
    <UcModal ref="award" :closeBottom="false">
      <div class="color-white p-20">
        <p class="t-center fs-18">获奖者</p>
        无论是选择玩老虎机、视频扑克还是轮盘赌和二十一点等桌面游戏，无论是在桌面还是移动设备上，所有赌场游戏都充满乐趣，而且运气随时可能降临。自
        1998
        年以来，我们已经支付了很多获奖者，并且几乎每天都会有新的获奖者加冕。无论是老虎机或轮盘赌上的幸运旋转，视频扑克游戏中正确的手牌，还是二十一点中发的正确牌，很多玩家都已经体验过获胜手牌或旋转，并且继续这样做——看看下面我们目前的获奖者名单。
      </div>
    </UcModal>
    <UcModal ref="contact" :closeBottom="false">
      <div class="color-white p-20">
        <p class="t-center fs-18">联系我们</p>
        友好而专业的支持团队每周 7 天、每天 24
        小时为玩家解答任何疑问。支持以多种语言提供，可以通过电子邮件或实时聊天联系。实时聊天功能可以在桌面和移动设备上启动，对于任何可能需要立即关注的查询，建议选择该选项，而电子邮件可用于不太紧急的查询。无论是忘记密码还是提款查询，对于支持团队来说，没有什么问题太大或太小。
        1998
        年以来，我们已经支付了很多获奖者，并且几乎每天都会有新的获奖者加冕。无论是老虎机或轮盘赌上的幸运旋转，视频扑克游戏中正确的手牌，还是二十一点中发的正确牌，很多玩家都已经体验过获胜手牌或旋转，并且继续这样做——看看下面我们目前的获奖者名单。
      </div>
    </UcModal>
  </div>
</template>

<script>
// import LayoutFooter from "@/layout/layout-footer"
// import { UserOutlined, LockOutlined } from "@ant-design/icons-vue"

export default {
  name: "faq",
  components: {},
  data() {
    return {};
  },
  methods: {
    onShowLogin() {
      // this.$refs.login.show()
    },
  },
};
</script>

<style lang="less" scoped></style>
