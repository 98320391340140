// i18n.js
import { createI18n } from "vue-i18n";
import zn from "./zn.json";
import en from "./en.json";
import pt from "./pt.json";

const messages = {
  zn: {
    g: zn,
  },
  en: {
    g: en,
  },
  pt: {
    g: pt,
  },
};

const i18n = createI18n({
  locale: "zn", // set default locale
  fallbackLocale: "zn", // set fallback locale
  messages, // set locale messages
});

export default i18n;
