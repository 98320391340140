<template>
  <div
    class="modal-page w-fill flex flex-1 flex-dc"
    style="background: var(--main)"
  >
    <div class="modal-wrap flex flex-1 flex-dc p-0">
      <UcTopBar title="提现记录" />
      <div class="content flex flex-1 flex-dc m-h-100" style="overflow: auto">
        <div class="card-list m-b-15">
          <div
            class="card-item flex flex-dc p-15"
            style="
              background: var(--main);
              color: var(--txt1);
              border-bottom: 1px solid var(--hight);
            "
            v-for="(i, index) in $store.state.wallet.withdrawals"
          >
            <div class="flex flex-sb m-b-4">
              <span>{{ i.id }}</span>
              <span>
                {{ $t(`g.金额变化`) }}
                <span style="color: #55cf76" class="m-l-4">{{ i.amount }}</span>
              </span>
            </div>
            <div class="flex flex-sb">
              <span>{{
                $dayjs(i.created_at).format("YYYY-MM-DD HH:mm:ss")
              }}</span>
              <span>
                {{ $t(`g.账户余额`) }}
                <span class="m-l-4" style="color: #e7e7e7">{{
                  i.balance
                }}</span>
              </span>
            </div>
          </div>
          <div class="more flex flex-center pointer m-t-20">
            <div
              @click="onMore"
              v-if="
                $store.state.wallet.withdrawals.length !==
                $store.state.wallet.withdrawalMeta.total
              "
            >
              <span style="color: #ffefba">
                <span class="m-r-8"> {{ $t(`g.更多`) }}</span>
                <DoubleRightOutlined
                  class="fs-12"
                  style="transform: rotate(90deg)"
                />
              </span>
            </div>
            <div v-else>
              <span style="color: #ffefba">
                <span class="m-r-8"> {{ $t(`g.没有更多了`) }}</span>
              </span>
            </div>
          </div>
        </div>
      </div>
      <div class="bottom-beat bg"></div>
    </div>
  </div>
</template>

<script>
export default {
  name: "xx",
  data() {
    return {
      page: 1,
      loading: true,
    };
  },
  async created() {
    this.$nextTick(async () => {
      this.init();
    });
  },
  methods: {
    async init() {
      await this.$store.dispatch("wallet/loadWithdrawalRecord", {
        page: this.page,
      });
    },
    onMore() {
      this.page++;
      this.init();
    },
  },
};
</script>

<style lang="less" scoped></style>
