import { createRouter, createWebHistory } from "vue-router";
import Home from "../views/home.vue";

// auth
import login from "../views/auth/login.vue";
import register from "../views/auth/register.vue";

// left menu
import vip from "../views/vip/index.vue";

// wallet
import bankList from "../views/wallet/bank-list.vue";
import bankAdd from "../views/wallet/bank-add.vue";
import cashier from "../views/wallet/cashier.vue";
import rechargeRecord from "../views/wallet/recharge-record.vue";
import walletRecord from "../views/wallet/wallet-record.vue";
import txRecord from "../views/wallet/tx-record.vue";

// game
import gameSearch from "../views/game/search.vue";
import gameDetails from "../views/game/details.vue";
import gamePlay from "../views/game/play.vue";
import bettingRecord from "../views/game/betting-record.vue";

// record
import bettingList from "../views/record/betting/list.vue";
import bettingDetails from "../views/record/betting/details.vue";
import gameRecord from "../views/record/game/list.vue";
import gameRecordDetails from "../views/record/game/details.vue";

import accountChange from "../views/record/account-change.vue";

// my
import my from "../views/my/index.vue";
import opinion from "../views/my/opinion.vue";
import noticeDetails from "../views/my/notice-details.vue";
import notice from "../views/my/notice.vue";
import infoEdit from "../views/my/info-edit.vue";
import editPwd from "../views/my/edit-pwd.vue";
import findPwd from "../views/my/find-pwd.vue";

// agent
import agent from "../views/agent/index.vue";
import course from "../views/agent/course.vue";
import myMoney from "../views/agent/my-money.vue";
import agentMoney from "../views/agent/agent-money.vue";
import teamData from "../views/agent/team-data.vue";
import bonus from "../views/agent/bonus.vue";
import link from "../views/agent/link.vue";
import share from "../views/agent/share.vue";

// Activity
import Activity from "../views/activity/list.vue";
import ActivityDetails from "../views/activity/details.vue";

//  FAQ
import FAQ from "../views/FAQ/index.vue";

// service
import Service from "../views/service/index.vue";

// m
import m from "../views/m/index.vue";

import test from "../views/test.vue";

const routes = [
  {
    path: "/",
    redirect: "/m",
  },
  {
    path: "/home",
    name: "home",
    component: Home,
  },
  {
    path: "/test",
    name: "test",
    component: test,
  },
  {
    path: "/m",
    name: "m",
    component: m,
  },
  {
    path: "/login",
    name: "login",
    component: login,
  },
  {
    path: "/register",
    name: "register",
    component: register,
  },
  {
    path: "/activity",
    name: "activity",
    component: Activity,
  },
  {
    path: "/faq",
    name: "faq",
    component: FAQ,
  },
  {
    path: "/service",
    name: "service",
    component: Service,
  },
  {
    path: "/activity-details",
    name: "activity-details",
    component: ActivityDetails,
  },
  {
    path: "/vip",
    name: "vip",
    component: vip,
  },
  {
    path: "/bank-list",
    name: "bank-list",
    component: bankList,
  },
  {
    path: "/bank-add",
    name: "bank-add",
    component: bankAdd,
  },
  {
    path: "/cashier",
    name: "cashier",
    component: cashier,
  },
  {
    path: "/recharge-record",
    name: "recharge-record",
    component: rechargeRecord,
  },
  {
    path: "/game-play",
    name: "game-play",
    component: gamePlay,
  },
  {
    path: "/betting-record",
    name: "betting-record",
    component: bettingRecord,
  },
  {
    path: "/game-details/:id",
    name: "game-details",
    component: gameDetails,
  },
  {
    path: "/betting-list",
    name: "betting-list",
    component: bettingList,
  },
  {
    path: "/wallet-record",
    name: "wallet-record",
    component: walletRecord,
  },
  {
    path: "/tx-record",
    name: "tx-record",
    component: txRecord,
  },
  {
    path: "/betting-details",
    name: "betting-details",
    component: bettingDetails,
  },
  {
    path: "/game-record",
    name: "game-record",
    component: gameRecord,
  },
  {
    path: "/game-record-details",
    name: "game-record-details",
    component: gameRecordDetails,
  },
  {
    path: "/account-change",
    name: "account-change",
    component: accountChange,
  },
  {
    path: "/account-change",
    name: "account-change",
    component: accountChange,
  },
  {
    path: "/my",
    name: "my",
    component: my,
  },
  {
    path: "/opinion",
    name: "opinion",
    component: opinion,
  },
  {
    path: "/notice",
    name: "notice",
    component: notice,
  },
  {
    path: "/notice-details",
    name: "notice-details",
    component: noticeDetails,
  },
  {
    path: "/info-edit",
    name: "info-edit",
    component: infoEdit,
  },
  {
    path: "/edit-pwd",
    name: "edit-pwd",
    component: editPwd,
  },
  {
    path: "/find-pwd",
    name: "find-pwd",
    component: findPwd,
  },
  {
    path: "/game-search",
    name: "game-search",
    component: gameSearch,
  },
  {
    path: "/agent",
    name: "agent",
    component: share,
  },
  {
    path: "/course",
    name: "course",
    component: course,
  },
  {
    path: "/my-money",
    name: "my-money",
    component: myMoney,
  },
  {
    path: "/agent-money",
    name: "agent-money",
    component: agentMoney,
  },
  {
    path: "/team-data",
    name: "tea-data",
    component: teamData,
  },
  {
    path: "/bonus",
    name: "bonus",
    component: bonus,
  },
  {
    path: "/link",
    name: "link",
    component: link,
  },
];

const router = createRouter({
  // strict: true,
  routes,
  history: createWebHistory(),
});

router.beforeEach(async () => {
  return true;
});

export default router;
