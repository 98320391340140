import { message } from "ant-design-vue";
import createRestfulModule from "./restful-module";
import { agent } from "@/api/modules/apis";

const store = {
  state: {
    info: {},
  },
  mutations: {
    setState(state, data) {
      state[data.attr] = data.state;
    },
  },
  actions: {
    async loadAgent({ _, commit }) {
      const res = await agent.agentApi.get();
      commit("setState", { attr: "info", state: res.data.data });
    },
    async loadTabs({ _, commit }) {
      let res = await gameTypeApi.get({});
      let tabs = { id: 0, name: "热门", code: "hot", img: "menu-hot" };
      res.data.data.forEach((i) => {
        if (i.id === 1) i.img = "menu-777";
      });

      res.data.data.unshift(tabs);
      commit("setState", { attr: "tabs", state: res.data.data });
    },
    async getDetails({ _, commit }, data) {
      let res = await detailsApi.post(data);
      // return await noticeDetailsApi.post(data)
      commit("setDocument", res.Data.announcement[0]);
    },
    async loadHomeInfo({ _, commit }, data) {
      const res = await listApi.post(data);
      commit("setList", res.Data);
    },
  },
};

export default createRestfulModule(store);
