import config from "@/config"
import server from "@/utils/request"
import _ from "lodash"

// 基础 api
export class ApiBase {
  constructor() {
    this.baseURL = config.baseURL
    this.resourcePath = ""
    this.notAllowUpdateProps = []
    this.overrideMethod = undefined
  }

  setOverrideMethod(method) {
    this.overrideMethod = method
    return this
  }

  async request(
    path,
    method = "GET",
    data = undefined,
    query = undefined,
    headers = {}
  ) {
    const url = `${this.baseURL}${path}`
    const opts = { url, method }
    if (method === "GET") {
      opts.params = data
    } else {
      opts.data = data
      opts.params = query
    }

    if (this.overrideMethod) {
      // opts.headers = {
      //   "X-HTTP-METHOD-OVERRIDE": this.overrideMethod,
      //   "Content-Type": "application/x-www-form-urlencoded",
      // }
      // this.overrideMethod = undefined
    }

    // if (Object.keys(headers).length) {
    //   opts.headers = {}
    //   Object.assign(opts.headers, {
    //     ...headers,
    //   })
    // }

    const response = await server(opts)
    return response
  }

  buildQueryParams(queryParams) {
    const {
      filters,
      relations,
      relation_filters,
      sorts,
      fields,
      size,
      page,
      all,
      other,
      ...residue
    } = queryParams

    // 适配规则
    queryParams.Page = queryParams.page
    queryParams.Size = queryParams.size
    delete queryParams.page
    delete queryParams.size
    queryParams.Condition = _.cloneDeep(queryParams.filters)
    delete queryParams.filters

    if (all) {
      delete queryParams.page
      delete queryParams.size
    }
    return queryParams
    const restParams = {
      filters: filters ? JSON.stringify(filters) : undefined,
      relations: relations ? relations.join(",") : undefined,
      relation_filters: relation_filters
        ? JSON.stringify(relation_filters)
        : undefined,
      sorts: sorts ? sorts.join(",") : undefined,
      fields: fields ? JSON.stringify(fields) : undefined,
      ...residue,
    }

    const newParams = {}
    Object.keys(restParams).forEach((key) => {
      if (restParams[key] !== undefined) {
        newParams[key] = restParams[key]
      }
    })
    newParams
    return newParams
  }

  getNotAllowedProps() {
    return this.notAllowUpdateProps || []
  }

  async paginator(queryParams = {}) {
    return this.request(
      this.resourcePath,
      "GET",
      this.buildQueryParams(queryParams)
    )
  }

  async list(data = {}) {
    return this.request(
      `${this.resourcePath}`,
      "POST",
      this.buildQueryParams(data)
    )
  }

  async get(id) {
    return this.request(`${this.resourcePath}/${id}`, "GET")
  }

  async post(data) {
    return this.request(`${this.resourcePath}`, "POST", data)
  }

  async create(data) {
    return this.request(this.resourcePath, "POST", data)
  }

  async update(id, data) {
    return this.request(
      `${this.resourcePath}`,
      "PUT",
      this.buildQueryParams(data)
    )
    // this.getNotAllowedProps().forEach(prop => delete data[prop]);
  }

  async delete(id) {
    return this.request(`${this.resourcePath}`, "DELETE", {}, { id: +id })
  }

  async deleteBatch(data) {
    return this.request(`${this.resourcePath}`, "DELETE", data)
  }

  async document(id) {
    return this.request(`${this.resourcePath}`, "GET", { id: +id })
  }

  async getQuery(data) {
    return this.request(`${this.resourcePath}`, "GET", data)
  }
}

// 基础 factory
export const baseFactory = (baseURL) => {
  return {
    restful(path, notAllowUpdateProps = []) {
      const baseApi = new ApiBase()
      baseApi.baseURL = baseURL
      baseApi.resourcePath = path
      baseApi.notAllowUpdateProps = notAllowUpdateProps
      return baseApi
    },
    command(path, headers = {}) {
      const baseApi = new ApiBase()
      baseApi.baseURL = baseURL
      return {
        get: (query = {}) => baseApi.request(path, "GET", query),
        post: (data, query = {}) =>
          baseApi.request(path, "POST", data, query, headers),
        put: (data, query = {}) =>
          baseApi.request(path, "PUT", data, query, headers),
      }
    },
  }
}
