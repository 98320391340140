<template>
  <div>
    <div
      class="layout-left flex-shrink-0"
      :class="$store.state.global.menuShow ? 'show' : 'hide'"
      style="overflow: scroll"
    >
      <div
        :class="$store.state.global.menuShow ? 'show' : 'hide'"
        class="laypit-left-box"
      >
        <div class="layout-left__wrap p-10 p-rel">
          <a
            class="p-abs"
            style="z-index: -1"
            v-for="(i, index) in $store.state.global.tabs"
            :ref="`#${i.id}`"
            :href="`#${i.id}`"
            >{{ i.name }}</a
          >
          <!-- 菜单 -->
          <div class="menu flex flex-wrap flex-sb">
            <div
              class="menu-item pointer flex flex-dc flex-center"
              v-for="(i, index) in $store.state.global.tabs"
              :key="index"
              @click="onChangeTab(i.id)"
              :class="i.id === $store.state.global.tabKey ? 'cur' : ''"
            >
              <img
                v-if="i.id === $store.state.global.tabKey"
                class="fill"
                :src="require(`@/assets/images/${i.img}-fill.png`)"
                style="width: 40px; height: 40px"
              />
              <img
                v-else
                :src="require(`@/assets/images/${i.img}.png`)"
                style="width: 40px; height: 40px"
              />
              <span v-if="i.id === 0">{{ $t(`g.${i.name}`) }}</span>
              <span v-else>{{ i.name }}</span>
            </div>
          </div>
          <!-- 背景音乐 -->
          <!-- <div class="bgs-sub bor-8 flex flex-cc flex-dc p-10">
        <div class="flex w-fill flex-cc flex-sa flex-center color-default">
          <StepBackwardOutlined class="fs-20" />
          <CaretRightOutlined class="fs-20" />
          <StepForwardOutlined class="fs-20" />
          <div>
            <i class="iconfont icon-xunhuanbofang-1 fs-20"></i>
          </div>
          <i class="iconfont icon-yinleliebiao fs-20"></i>
        </div>
        <div class="w-fill color-7 text-ellipsis">
          MEIA NOITE (Você tem meu Whatsapp).mp3
        </div>
      </div> -->
          <!-- 投注记录 -->
          <div
            class="bgs-sub bor-8 flex flex-sa flex-cc p-10 pointer"
            @click="toPageTz"
          >
            <img src="@/assets/images/money-record.png" style="width: 30px" />
            <span class="color-7">{{ $t(`g.投注记录`) }}</span>
          </div>
          <!-- 附属菜单 -->
          <div
            class="sub-menu bgs-sub bor-8 m-t-10 flex p-8 flex flex-sb flex-wrap"
          >
            <div class="p-rel" @click="toPageHd">
              <span class="p-abs">{{ $t(`g.活动`) }}</span>
              <img src="@/assets/images/sub-menu-gift.png" />
            </div>
            <div class="p-rel" @click="mixMsg($t(`g.正在维护`))">
              <span class="p-abs">{{ $t(`g.会员`) }}</span>
              <img src="@/assets/images/sub-menu-vip.png" />
            </div>
            <!-- <div class="p-rel">
            <span class="p-abs">福利</span>
            <img src="@/assets/images/sub-menu-welfare.png" />
          </div> -->
            <!-- <div class="p-rel" @click="$refs.accountChange.show()">
              <span class="p-abs">历史</span>
              <img src="@/assets/images/sub-menu-record.png" />
            </div>
            <div class="p-rel" @click="$router.push('/course')">
              <span class="p-abs">奖励</span>
              <img src="@/assets/images/sub-menu-box.png" />
            </div> -->
            <div class="p-rel" @click="toPageTg">
              <span class="p-abs">{{ $t(`g.邀请有礼`) }}</span>
              <img
                src="@/assets/images/sub-menu-invite.png"
                class="w-fill m-b-0"
              />
            </div>
          </div>
          <!-- 其他 -->
          <div class="other p-10">
            <!-- <a-popconfirm placement="rightBottom" @confirm="confirm">
          <template #title>
            <div>333</div>
          </template>
          <div class="flex">
            <i class="iconfont icon-tongji"></i>
            <span class="color-7 p-l-10">线路</span>
          </div>
        </a-popconfirm> -->
            <UcLang direction="rightBottom">
              <div class="flex flex-cc flex-sb">
                <div class="flex">
                  <img src="@/assets/images/wangluo.png" alt="" srcset="" />
                  <span class="color-7">{{
                    $enums.languageType.filterValue($i18n.locale)
                  }}</span>
                </div>
                <CaretDownOutlined style="color: var(--default)" />
              </div>
            </UcLang>
            <!-- <div class="flex" @click="mixMsg('APP正在开发')">
              <img src="@/assets/images/xiazai.png" alt="" srcset="" />
              <span class="color-7">下载App</span>
            </div>
            <div class="flex" @click="mixMsg('正在开发')">
              <img src="@/assets/images/kefu.png" alt="" srcset="" />
              <span class="color-7">客服支持</span>
            </div> -->
            <!-- <div class="flex" @click="toFaq">
              <img src="@/assets/images/wenhao.png" alt="" srcset="" />
              <span class="color-7">{{ $t(`g.常见问题`) }}</span>
            </div> -->
          </div>
        </div>
        <UcModal ref="accountChange" :closeBottom="false">
          <AccountRecord />
        </UcModal>
        <UcModal ref="bettingList" :closeBottom="false">
          <BettingList />
        </UcModal>
        <UcModal ref="infoEdit" :closeBottom="false">
          <InfoEdit />
        </UcModal>
        <UcModal ref="Activity" :closeBottom="false">
          <Activity />
        </UcModal>
        <UcModal ref="Vip" :closeBottom="false">
          <Vip />
        </UcModal>
        <UcModal ref="Faq" :closeBottom="false">
          <Faq />
        </UcModal>
      </div>
    </div>
    <!-- 遮罩 -->
    <div
      v-if="$store.state.global.menuShow"
      @click="onChangeMenuShow(false)"
      class="m-show p-fix w-fill h-fill"
      style="background: rgba(0, 0, 0, 0.5); z-index: 998; left: 0; top: 0"
    ></div>
  </div>
</template>

<script>
import _ from "lodash";

import {
  StepBackwardOutlined,
  StepForwardOutlined,
  CaretRightOutlined,
  PauseOutlined,
  CaretDownOutlined,
} from "@ant-design/icons-vue";
import Activity from "../views/activity/list.vue";
import AccountRecord from "../views/record/account-change.vue";
import BettingList from "../views/record/betting/list.vue";
import InfoEdit from "../views/my/info-edit.vue";
import Vip from "../views/vip/index.vue";
import Faq from "../views/FAQ/index.vue";

export default {
  name: "layout-left",
  components: {
    AccountRecord,
    StepBackwardOutlined,
    StepForwardOutlined,
    CaretRightOutlined,
    PauseOutlined,
    BettingList,
    InfoEdit,
    Activity,
    Vip,
    Faq,
    CaretDownOutlined,
  },
  data() {
    return {};
  },
  computed: {
    imageUrl(src) {
      // 使用require动态加载图片
      return require(`../assets/images/${src}.png`);
    },
  },
  methods: {
    onChangeMenu(i, index) {
      this.$store.commit("global/setMenu", []);
    },
    onChangeMenuShow(status) {
      this.$store.commit("global/setMenuShow", status);
    },
    onChangeTab(key) {
      this.$refs[`#${key}`][0].click();
      this.$store.commit("global/setState", { attr: "tabKey", state: key });
      this.onChangeMenuShow(false);
    },
    toFaq() {
      this.onChangeMenuShow(false);
      this.mixToPage("faq", "Faq");
    },
    toPageTz() {
      this.onChangeMenuShow(false);
      this.mixToPage("betting-record", null, "page");
    },
    toPageHd() {
      this.onChangeMenuShow(false);
      this.$router.push({ path: "/activity", query: { back: true } });
    },
    toPageTg() {
      this.onChangeMenuShow(false);
      this.$router.push({ path: "/agent", query: { back: true } });
    },
  },
};
</script>

<style lang="less" scoped>
.menu-item.cur {
  span {
    color: var(--main) !important;
  }
}
.laypit-left-box {
  position: relative;
  left: 0;
}
.laypit-left-box.hide {
  display: none;
}

.layout-left.hide {
  width: 0;
}
.layout-left {
  transition: all 0.5s;
  width: 260px;
  height: calc(100vh - 80px);
  background: #008daa;
  box-shadow: 20px 0 22px 0 rgba(0, 0, 0, 0.1);
  position: relative;
  z-index: 2;
  .menu {
    &-item {
      width: 115px;
      height: 84px;
      color: rgba(255, 255, 255, 0.7);
      background: #3bb3d6;
      border-radius: 7px;
      margin-bottom: 10px;
    }
    &-item.cur {
      background: #ffefba;
      color: #3bb3d6;
    }
    &-item:hover {
      color: #fff;
      img {
        display: none;
      }
      .fill {
        display: block !important;
      }
    }
  }
  .sub-menu {
    .p-rel {
      cursor: pointer;
    }
    img {
      height: 70px;
      margin-bottom: 8px;
    }
    .p-abs {
      color: #fff;
      padding: 8px;
    }
  }
  .other > div {
    display: flex;
    align-items: center;
    padding: 5px;
    cursor: pointer;
    i.icon-tongji {
      padding-left: 3px;
      font-size: 20px;
      color: #04be02;
    }
    img {
      margin-right: 9px;
    }
  }
}
</style>
