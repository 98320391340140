// 国际化
export const languageType = Object.freeze({
  zn: "zn",
  en: "en",
  pt: "pt",

  options() {
    return [
      { label: "简体中文", value: this.zn, lang: "zn" },
      { label: "English", value: this.en, lang: "en" },
      { label: "Português brasileiro", value: this.pt, lang: "pt" },
    ];
  },
  filterValue(value) {
    return this.options().find((item) => item.value == value)?.label;
  },
});

// 订单状态
export const orderStatus = Object.freeze({
  ing: 0,
  susccess: 1,

  options() {
    return [
      { label: "充值中", value: this.ing },
      { label: "充值成功", value: this.susccess },
    ];
  },
  filterValue(value) {
    return this.options().find((item) => item.value == value)?.label;
  },
});

// 提现类型
export const txType = Object.freeze({
  CPF: "CPF",
  CNPJ: "CNPJ",
  EMAIL: "EMAIL",
  PHONE: "PHONE",
  EVP: "EVP",

  options() {
    return [
      { label: "CPF", value: this.CPF },
      { label: "CNPJ", value: this.CNPJ },
      { label: "EMAIL", value: this.EMAIL },
      { label: "PHONE", value: this.PHONE },
      { label: "EVP", value: this.EVP },
    ];
  },
  filterValue(value) {
    return this.options().find((item) => item.value == value)?.label;
  },
});

// 筛选枚举对象
const filterValue = (options, value) => {
  return options.find((item) => item.value === value) ?? {};
};

export default {
  filterValue,
  languageType,
  orderStatus,
  txType,
};
