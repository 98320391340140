<template>
  <div class="page m w-fill flex flex-1 flex-dc">
    <div class="m-wrap flex flex-1 flex-dc">
      <div class="m-content flex flex-1 flex-dc p-rel">
        <div
          class="scroll p-abs w-fill h-fill"
          style="left: 0; top: 0; overflow: auto"
        >
          <Home v-show="navIndex === 0" />
          <Activity v-if="navIndex === 1" />
          <Agent v-if="navIndex === 2" />
          <Recharge v-if="navIndex === 3" />
          <My v-show="navIndex === 4" />
        </div>
      </div>
      <div
        class="bottomBar-beat w-fill"
        style="height: var(--bottomBarH)"
      ></div>
      <div
        class="bottomBar w-fill p-fix nav flex flex-sb flex-cc color-white p-lr-20"
        style="
          height: var(--bottomBarH);
          bottom: 0;
          z-index: 999;
          max-width: var(--mixWidM);
        "
      >
        <div
          @click="onChangeNav(index)"
          class="nav-item flex flex-dc flex-sb"
          :class="i.cur ? 'cur' : ''"
          v-for="(i, index) in navs"
          :key="index"
        >
          <HomeOutlined v-if="index === 0" class="fs-22 m-b-2" />
          <GiftOutlined v-if="index === 1" class="fs-22 m-b-2" />
          <AppstoreAddOutlined v-if="index === 2" class="fs-22 m-b-2" />
          <AccountBookOutlined v-if="index === 3" class="fs-22 m-b-2" />
          <UserOutlined v-if="index === 4" class="fs-22 m-b-2" />
          {{ $t(`g.${i.label}`) }}
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {
  HomeOutlined,
  GiftOutlined,
  AppstoreAddOutlined,
  AccountBookOutlined,
  UserOutlined,
} from "@ant-design/icons-vue";

import Home from "@/views/home.vue";
import Activity from "@/views/activity/list.vue";
import Agent from "@/views/agent/share.vue";
import My from "@/views/my/index.vue";
import LayoutHeader from "@/layout/layout-header";
import Recharge from "@/views/wallet/recharge.vue";

export default {
  name: "activity",
  components: {
    Home,
    Activity,
    Agent,
    My,
    Recharge,
    LayoutHeader,
    HomeOutlined,
    GiftOutlined,
    AppstoreAddOutlined,
    AccountBookOutlined,
    UserOutlined,
  },
  data() {
    return {
      navIndex: 0,
      navs: [
        { label: "首页", cur: true },
        { label: "活动", cur: false },
        { label: "推广", cur: false },
        { label: "充值", cur: false },
        { label: "我的", cur: false },
      ],
    };
  },
  created() {
    this.navIndex = this.$store.state.global.navTabIdx;
    this.onChangeNav(this.navIndex);
  },
  methods: {
    onChangeNav(index) {
      if (!this.$store.state.auth.userInfo.name)
        return this.mixMsg(this.$t(`g.请先登录`));
      this.navIndex = index;
      this.navs.forEach((i) => (i.cur = false));
      this.navs[index].cur = true;
      this.navs.splice(0, 0);
      this.$store.commit("global/setState", {
        attr: "navTabIdx",
        state: index,
      });
      console.log(345);
      window.scrollTo(0, 0);
    },
  },
};
</script>

<style lang="less" scoped>
.nav {
  width: 100%;
  height: 74px;
  background: var(--main);
  border-top: 1px solid #00a8cc;
}
.nav-item {
  color: #86e4f5;
}
.nav-item.cur {
  color: var(--default);
}
.m-content {
  overflow-y: scroll;
  height: calc(100% - var(--bottomBarH));
}
</style>
