<template>
  <div class="my modal-page page-m page-m-tab w-fill flex flex-1 flex-dc">
    <div class="modal-wrap flex flex-1 p-0">
      <div
        class="modal-content content flex flex-1 flex-dc p-10"
        style="background-color: var(--main)"
      >
        <div
          class="tootls flex flex-right"
          v-if="$store.state.auth.userInfo.name"
        >
          <div class="flex flex-dc flex-cc" @click="$router.push('/service')">
            <CustomerServiceOutlined
              class="bold fs-16"
              style="color: var(--default)"
            />
            <span class="color-white">{{ $t(`g.客服`) }}</span>
          </div>
        </div>
        <div class="info pointer color-hight p-10 bor-8 m-b-12">
          <div
            class="flex flex-cc flex-sb"
            @click="mixToPage('info-edit', 'infoEdit')"
            v-if="$store.state.auth.userInfo.name"
          >
            <div class="flex flex-cc">
              <img
                src="@/assets/images/avatar.png"
                style="width: 50px"
                class="m-r-10"
              />
              <div
                class="flex flex-dc fs-14 color-white"
                style="font-weight: 500"
              >
                <div>
                  {{ $store.state.auth.userInfo.name }}
                  <!-- <div class="vip-brage vip3">VIP3</div> -->
                </div>
                <span class="fs-16"
                  >ID：{{ $store.state.auth.userInfo.id }}</span
                >
              </div>
            </div>
          </div>
          <div
            v-else
            class="p-tb-10 p-lr-15 fs-18 flex flex-cc flex-center"
            @click="$refs.Login.show()"
          >
            <span class="m-r-14">{{ $t(`g.登录`) }}</span>
            <RightOutlined class="fs-16" />
          </div>
        </div>
        <!--           @click="mixMsg('正在维护')" -->
        <div
          style="background: var(--default)"
          class="bor-8 p-14 m-b-30 m-t-20"
        >
          vip
        </div>
        <div class="sub-menu flex flex-wrap flex-sb">
          <!-- <div class="item" @click="mixMsg('正在维护')">
            <span>余额宝</span><RightOutlined />
          </div> -->
          <!-- <div class="item" @click="mixToPage('notice', 'notice')">通知</div> -->
          <div class="item" @click="mixToPage('betting-record', null, 'page')">
            <span>{{ $t(`g.投注记录`) }}</span
            ><RightOutlined />
          </div>
          <!-- <div class="item" @click="mixToPage('game-record', 'GameRecord')">
            游戏记录
          </div> -->
          <div class="item" @click="mixToPage('recharge-record', null, 'page')">
            <span>{{ $t(`g.充值记录`) }}</span
            ><RightOutlined />
          </div>
          <div class="item" @click="mixToPage('wallet-record', null, 'page')">
            <span>{{ $t(`g.提现记录`) }}</span
            ><RightOutlined />
          </div>
          <div class="item" @click="mixToPage('tx-record', null, 'page')">
            <span>{{ $t(`g.交易明细`) }}</span
            ><RightOutlined />
          </div>
          <div class="item" @click="toTx">
            <span>{{ $t(`g.提现`) }}</span
            ><RightOutlined />
          </div>
          <!-- <div class="item" @click="mixToPage('opinion', 'opinion')">
            意见反馈
          </div>
          <div class="item" @click="mixToPage('info-edit', 'infoEdit')">
            账户设置
          </div>
          <div class="item" @click="mixToPage('find-pwd', 'FindPwd')">
            找回密码
          </div>
          <div class="item" @click="mixToPage('edit-pwd', 'EditPwd')">
            重置密码
          </div> -->
        </div>
        <div
          class="flex flex-center color-hight p-b-12"
          v-if="$store.state.auth.userInfo.name"
        >
          <van-loading v-if="loadingLogout" size="16" class="m-r-4" />
          <span class="pointer" @click="loginout"
            >{{ $t(`g.退出登录`)
            }}<LoginOutlined class="m-l-8 color-default bold"
          /></span>
        </div>
        <div class="bottom-beat bg"></div>
      </div>
    </div>
    <UcModal ref="opinion" :closeBottom="false">
      <Opinion />
    </UcModal>
    <UcModal ref="notice" :closeBottom="false">
      <Notice />
    </UcModal>
    <UcModal ref="infoEdit" :closeBottom="false">
      <InfoEdit />
    </UcModal>
    <UcModal ref="GameRecord" :closeBottom="false">
      <GameRecord />
    </UcModal>
    <UcModal ref="EditPwd" :closeBottom="false">
      <EditPwd />
    </UcModal>
    <UcModal ref="FindPwd" :closeBottom="false">
      <FindPwd />
    </UcModal>
    <UcModal ref="Login">
      <Login />
    </UcModal>
    <UcModal ref="Withdrawal">
      <Withdrawal />
    </UcModal>
  </div>
</template>

<script>
import Opinion from "./opinion";
import Notice from "./notice";
import InfoEdit from "./info-edit";
import GameRecord from "@/views/record/game/list";
import EditPwd from "./edit-pwd";
import FindPwd from "./find-pwd";
import Login from "@/views/auth/login";
import Withdrawal from "@/views/wallet/withdrawal";

import {
  LoginOutlined,
  RightOutlined,
  CustomerServiceOutlined,
} from "@ant-design/icons-vue";

export default {
  name: "my",
  components: {
    Withdrawal,
    LoginOutlined,
    RightOutlined,
    Opinion,
    Notice,
    InfoEdit,
    GameRecord,
    EditPwd,
    FindPwd,
    Login,
    CustomerServiceOutlined,
  },
  data() {
    return {
      loadingLogout: false,
    };
  },
  mounted() {
    // this.$refs.notice.show()
  },
  methods: {
    onShowLogin() {
      this.$refs.infoEdit.show();
    },
    async loginout() {
      this.loadingLogout = true;
      await this.$store.dispatch("auth/logout", this);
      this.mixMsg("退出登录成功");
      this.loadingLogout = false;
      this.$router.replace("/");
    },
    toTx() {
      this.$refs.Withdrawal.show();
      this.$store.dispatch("wallet/loadBalance", this);
    },
  },
};
</script>

<style lang="less" scoped>
.content {
  background-image: url("@/assets/images/my-bg.png");
  background-repeat: no-repeat;
  background-size: 100% auto;
}
.modal-wrap {
  border-radius: 8px;
}
.sub-menu {
  .item {
    width: 48%;
    height: 50px;
    background: var(--sub);
    color: var(--hight);
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 10px;
    margin-bottom: 10px;
    cursor: pointer;
  }
  .item:hover {
    background: #2facce;
  }
}
</style>
