import { apiFactory } from "@/api/index";

export const csrfApi = apiFactory.command(`/api/csrf-cookie`);
export const loginApi = apiFactory.command(`/api/login`);
export const registerApi = apiFactory.command(`/api/register`);
export const updatePwdApi = apiFactory.command(`/api/user/password`);
export const updateInfoApi = apiFactory.command(
  `/api/user/profile-information`
);

export const yzmApi = apiFactory.command(`/api/getimagecode`, {
  "Content-Type": "application/x-www-form-urlencoded",
});
export const userinfoApi = apiFactory.command(`/api/user`);
export const logoutApi = apiFactory.command(`/api/logout`);
