/**
 * 数据转换下拉框选型数据
 * @param {Array} items
 * @param {String} labelKey
 * @param {String} valueKey
 * @param {Array} otherAttributes
 * @returns {Array}
 */
export const useTransformOptions = (
  items,
  labelKey,
  valueKey,
  otherAttributes = []
) => {
  const options = [];

  items.forEach((item) => {
    let rawItem = {};
    Object.assign(rawItem, {
      label: item[labelKey],
      value: item[valueKey],
    });

    otherAttributes.forEach((attribute) => {
      Object.assign(rawItem, {
        [attribute]: item[attribute],
      });
    });
    options.push(rawItem);
  });
  return options;
};
