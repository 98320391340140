<template>
  <div class="app-wrap flex w-fill flex-dc">
    <!-- <div
      class="download w-fill flex flex-cc flex-sb"
      style="background: var(--main); border-bottom: 1px solid var(--hight)"
    >
      <div class="flex flex-cc">
        <CloseOutlined
          style="color: #ffdd6c"
          class="p-l-20 fs-16 m-r-20 bold"
        />
        <img
          src="@/assets/images/logo.png"
          style="width: 265px; height: 46px"
          alt=""
          srcset=""
        />
      </div>
      <div
        @click="$refs.login.show()"
        style="height: 30px; background: #ffdd6c"
        class="p-lr-10 bor-8 fs-14 color-main pointer flex flex-center m-r-12"
      >
        下载
      </div>
    </div> -->
    <!-- <UcTopBar class="m-show" /> -->

    <LayoutHeader v-if="isPc" class="m-hide m-hide-only" />
    <div class="flex flex-1 p-rel">
      <LayoutLeft />
      <div class="content app-content flex flex-dc flex-1">
        <router-view />
        <!-- <div class="app-beat w-fill" style="height: 50px"></div> -->
      </div>
    </div>
  </div>
</template>

<script>
import LayoutHeader from "./layout/layout-header";
import LayoutLeft from "./layout/layout-left";
import { CloseOutlined } from "@ant-design/icons-vue";
// import zhCN from "ant-design-vue/es/locale/zh_CN"

export default {
  name: "App",
  components: {
    LayoutHeader,
    LayoutLeft,
    CloseOutlined,
  },
  mounted() {
    console.log("version:1.0.0");
    // this.$store.dispatch("auth/csrf");
  },
};
</script>

<style lang="less" scoped>
.app-wrap {
  .content {
    height: calc(100vh - 80px);
    overflow: scroll;
  }
}
</style>
