<template>
  <div class="modal-page w-fill flex flex-1 flex-dc">
    <div class="modal-wrap flex flex-1 p-t-15">
      <div class="content flex flex-1 flex-dc">
        <div class="color-white">
          <p
            class="t-center fs-18 m-b-20 p-b-10"
            style="border-bottom: 1px solid var(--hight)"
          >
            银行卡
          </p>
          <div class="card-list" style="height: 400px; overflow: auto">
            <div
              class="card-item flex p-18 m-b-15"
              style="background: var(--default); border-radius: 10px"
              v-for="(i, index) in bankcards"
            >
              <img
                src="@/assets/images/bankcard.png"
                style="width: 32px; height: 32px"
                alt=""
                class="m-r-8"
                srcset=""
              />
              <div class="flex flex-dc flex-sb color-main">
                <span class="m-b-10">{{ i.name }}</span>
                <span>{{ i.card }}</span>
              </div>
            </div>
          </div>
          <div
            @click="mixToPage('bank-add', 'bankAdd')"
            style="height: 40px; border: 2px solid #ffefba"
            class="p-lr-10 bor-8 fs-16 color-default pointer flex flex-center m-l-15"
          >
            添加收款方式
          </div>
        </div>
      </div>
    </div>
    <UcModal ref="bankAdd" :class="'bankcard-modal'" :closeBottom="false">
      <BankAdd />
    </UcModal>
  </div>
</template>

<script>
import BankAdd from "./bank-add.vue"
// import { UserOutlined, LockOutlined } from "@ant-design/icons-vue"

export default {
  name: "bank-add",
  components: {
    BankAdd,
  },
  data() {
    return {
      bankcards: [
        { name: "Vietciombank", card: "123456789123" },
        { name: "Vietciombank", card: "123456789122" },
      ],
    }
  },
  mounted() {
    this.mixinMethod()
  },
  methods: {
    onShowLogin() {
      // this.$refs.login.show()
    },
  },
}
</script>

<style lang="less" scoped></style>
