import _ from "lodash"
// import { serverRunStatus } from "@/utils/enums";

const restfulModule = {
  state: {
    loading: false,
    collection: {
      list: [],
      total: 0,
      page: 1,
      size: 10,
    },
    document: {},
    options: [],
  },
  getters: {
    collection: (state) => state.collection.list,
    pagination: (state) => state.collection,
  },
  mutations: {
    setLoading(state, status) {
      state.loading = status
    },
    setCollection(state, collection) {
      state.collection = collection
    },
    setPagination(state, pagination) {
      Object.assign(state.collection, pagination)
    },
    setDocument(state, document) {
      state.document = document
    },
    setForm(state, form) {
      state.form = form
    },
    addForm(state, form) {
      state.form = { ...state.form, ...form }
    },
    setFilter(state, filter) {
      state.filter = filter
    },
    setOptions(state, list) {
      state.options = list
    },
    setList(state, list) {
      state.collection.list = list
      state.collection.list.splice(0, 0)
    },
  },
  actions: {
    async loadCollection({ state, commit }, form = {}) {
      let formParams = _.cloneDeep(form)
      let api = {}
      state.api.resourcePath ? (api = state.api) : (api = state.api.query)

      commit("setLoading", true)
      const { page, size } = state.collection
      let query = {
        filters: {},
        ..._.cloneDeep(state.basicsQuery),
        page,
        size,
        ...formParams.extend,
      }
      delete formParams.extend
      Object.assign(query.filters, formParams)

      let collection = await api.list(query)
      Object.assign(collection, {
        page: collection.Page,
        size: collection.Size,
        total: collection.Total,
        list: _.cloneDeep(collection.Values),
      })
      delete collection.Page
      delete collection.Size
      delete collection.Values
      delete collection.Total

      commit("setLoading", false)
      commit("setCollection", collection)
    },
    async loadOptions({ state, commit }, form = {}) {
      let api = {}
      state.api.resourcePath ? (api = state.api) : (api = state.api.query)

      let query = {
        filters: form,
        page: 1,
        size: 100000,
      }

      const collection = await api.list(query)
      commit("setOptions", collection.Values)
    },
    async document({ state }, id) {
      let api = state.api.document
      return await api.document(id)
    },
    async create({ state }, data) {
      let api = {}
      state.api.resourcePath ? (api = state.api) : (api = state.api.create)

      return await api.create({
        ...data,
        ID: 0,
      })
    },
    async update({ state }, data) {
      return await state.api.update.update(data.ID, data)
    },
    async delete({ state }, id) {
      return await state.api.delete.delete(id)
    },
    async deleteBatch({ state }, data) {
      return await state.api.deleteBatch.deleteBatch(data)
    },
    async getQuery({ state }, data) {
      let api = {}
      state.api.resourcePath ? (api = state.api) : (api = state.api.document)
      return await api.getQuery(data)
    },
    async allCollection({ commit, dispatch, state }) {
      const collection = await dispatch("find", { ...queryParams, all: true })
      commit("setCollection", collection)
      return state.collection
    },
    async batchCreate({ state }, dataArray) {
      return restfulClient.batchCreate(state.path, dataArray)
    },
    async replace({ state }, data) {
      const { id, ...props } = data
      return restfulClient.replace(state.path, id, props)
    },
  },
}

const moduleProps = ["state", "getters", "mutations", "actions"]
export default function createRestfulModule(module, namespaced = true) {
  module.namespaced = namespaced
  moduleProps.forEach((prop) => {
    module[prop] = { ...restfulModule[prop], ...(module[prop] || {}) }
  })
  return module
}
