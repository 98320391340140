<template>
  <div class="home flex w-fill flex-dc">
    <div
      class="download w-fill m-show"
      style="
        background: var(--main);
        border-bottom: 1px solid #00a8cc;
        position: sticky;
      "
    >
      <div class="flex flex-cc flex-sb" v-if="downloadShow">
        <div class="flex flex-cc">
          <CloseOutlined
            @click="onDownloadShow"
            style="color: #ffdd6c"
            class="p-l-20 fs-16 m-r-20 bold"
          />
          <img
            src="@/assets/images/logo.png"
            style="width: 212px; height: 46px"
            alt=""
            srcset=""
          />
        </div>
        <div
          @click="$refs.login.show()"
          style="height: 30px; background: #ffdd6c"
          class="p-lr-10 bor-8 fs-14 color-main pointer flex flex-center m-r-12"
        >
          {{ $t(`g.下载`) }}
        </div>
      </div>
    </div>
    <LayoutHeader class="m-show" style="position: sticky" />
    <div class="home-bg flex w-fill flex-dc p-rel">
      <div class="home-wrap flex flex-1 flex-dc">
        <!-- banner -->
        <div class="banner w-fill">
          <a-carousel autoplay :after-change="onBannerChange">
            <div>
              <img src="@/assets/images/banner1.png" alt="" srcset="" />
            </div>
            <div>
              <img src="@/assets/images/banner2.png" alt="" srcset="" />
            </div>
            <div>
              <img src="@/assets/images/banner3.png" alt="" srcset="" />
            </div>
            <div>
              <img src="@/assets/images/banner4.png" alt="" srcset="" />
            </div>
          </a-carousel>
        </div>
        <!-- notice -->
        <div class="notice flex p-tb-10 m-t-4">
          <SoundOutlined style="color: #98eeff" class="fs-18 p-r-6" />
          <div class="scroll-container flex flex-1">
            <div class="text" :class="{ 'animate-text': animateText }">
              <transition name="text-slide" mode="out-in">
                <span class="color-white" :key="text">{{ text }}</span>
              </transition>
            </div>
          </div>
        </div>
        <!-- <div class="s-nav m-show">
          <div class="s-nav__wrap flex flex-sb flex-cc">
            <div class="item" @click="$router.push('/activity')">
              <GiftFilled /><span>活动</span>
            </div>
            <div class="item" @click="$router.push('/agent-money')">
              <DollarCircleFilled /><span>奖金</span>
            </div>
            <div class="item" @click="$router.push('/vip')">
              <CrownFilled /><span>会员</span>
            </div>
            <div class="item" @click="$router.push('/vip')">
              <CustomerServiceFilled /><span>客服</span>
            </div>
          </div>
        </div> -->

        <!-- tabs -->
        <div class="category m-t-10 p-rel">
          <!-- {{ $store.state.global.tabs }} -->
          <a
            class="p-abs"
            style="z-index: -1"
            v-for="(i, index) in $store.state.global.tabs"
            :ref="`#${i.id}`"
            :href="`#${i.id}`"
          ></a>
          <van-tabs v-model:active="tabKey" @click="onChangeTab" shrink>
            <van-tab v-for="(i, index) in $store.state.global.tabs">
              <template #title>
                <div
                  class="flex flex-center flex-dc van-tab-item"
                  style="width: 80px"
                  :class="i.id === $store.state.global.tabKey ? 'cur' : ''"
                >
                  <img
                    class="flex flex-dc"
                    :src="require(`@/assets/images/${i.img}-fill.png`)"
                    style="width: 22px; height: 22px"
                  />
                  <div v-if="i.id === 0">{{ $t(`g.${i.name}`) }}</div>
                  <div v-else class="name">{{ i.name }}</div>
                </div>
              </template>
            </van-tab>
          </van-tabs>
          <!-- jackpot -->
          <div class="flex flex-sb">
            <div class="jackpot w-fill flex flex-sb m-t-30">
              <img class="laohu" :src="base64.laohu" />
              <div class="p-rel flex flex-1">
                <img
                  class="stats"
                  style="max-width: 344px; min-width: 200px"
                  src="@/assets/images/home-jackpot.png"
                  alt=""
                />
                <CountTo
                  class="p-abs color-white bold fs-20"
                  style="
                    left: 50%;
                    bottom: 15px;
                    transform: translate(-50%);
                    line-height: 1;
                  "
                  :startVal="count.startVal"
                  :endVal="count.endVal"
                  :duration="3000"
                />
              </div>
              <img
                class="laohu"
                style="transform: scaleX(-1)"
                :src="base64.laohu"
              />
            </div>
            <div class="s-nav flex flex-1" v-if="false">
              <div class="s-nav__wrap flex flex-sb flex-wrap flex-cc w-fill">
                <div class="flex flex-sb w-fill">
                  <div
                    class="item"
                    style="background: #5a94f7"
                    @click="$router.push('/activity?back=true')"
                  >
                    <GiftFilled /><span>{{ $t(`g.活动`) }}</span>
                  </div>
                  <div
                    class="item"
                    style="background: #e06656"
                    @click="toAgent"
                  >
                    <DollarCircleFilled /><span>{{ $t(`g.奖金`) }}</span>
                  </div>
                </div>
                <div class="flex flex-sb w-fill m-t-4">
                  <!-- {{ $t(`g.正在维护`) }} -->
                  <div class="item" style="background: #ba5aeb">
                    <CrownFilled /><span>{{ $t(`g.会员`) }}</span>
                  </div>
                  <div
                    class="item"
                    style="background: #eeb23e"
                    @click="$router.push('/service?back=true')"
                  >
                    <CustomerServiceFilled /><span>{{ $t(`g.客服`) }}</span>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <!-- pc tab -->
          <!-- <van-tabs v-model="tabKey" @change="onChangeTab">
            <van-tab
              v-for="(i, index) in $store.state.global.tabs"
              :key="index"
            >
              <div slot="title">
                {{ i.name }}
              </div>
            </van-tab>
          </van-tabs> -->
          <!-- <a-tabs
            v-model:activeKey="tabKey"
            style="position: sticky"
            @change="onChangeTab"
          >
            <a-tab-pane
              :key="i.id"
              :tab="i.name"
              v-for="(i, index) in $store.state.global.tabs"
            >
            </a-tab-pane>
          </a-tabs> -->

          <div id="0">
            <Hot />
          </div>
          <div id="1">
            <Dianzi />
          </div>
          <div class="category__content" v-if="false">
            <div
              class="category__item"
              v-for="(i, index) in gameCategory"
              :key="index"
            >
              <div class="category__item--title">
                <img :src="require(`@/assets/images/${i.img}-fill.png`)" />
                {{ i.name }}
              </div>
              <div class="category__item__game--list game-list flex flex-wrap">
                <div
                  class="game-list__item flex-s-0 bor-8 m-b-18 p-rel"
                  style="overflow: hidden"
                  v-for="(j, jindex) in i.gameList"
                  :key="index"
                  v-show="jindex < 7 || i.gameShow"
                >
                  <div class="p-abs flex flex-center">
                    <PlayCircleOutlined
                      style="color: #ffefba; font-size: 60px; opacity: 1"
                    />
                  </div>
                  <img :src="require(`@/assets/images/${j.img}.png`)" />
                </div>
              </div>
              <div
                v-if="i.gameList.length > 7 && !i.gameShow"
                class="more flex flex-center pointer"
              >
                <div @click="omMoreGame(i)">
                  <span style="color: #ffefba">
                    <span class="m-r-8">More</span>
                    <DoubleRightOutlined
                      class="fs-12"
                      style="transform: rotate(90deg)"
                    />
                  </span>
                </div>
              </div>
            </div>
          </div>
          <!-- <i class="iconfont icon-shanchu"></i> -->
        </div>
      </div>
      <!-- footer -->
      <LayoutFooter />
      <div class="bottom-beat bg"></div>
      <!-- fixed -->
      <div class="home-fixed p-fix" style="right: 0; bottom: 90px">
        <div class="flex flex-dc">
          <div class="p-rel" @click="toAgent" v-if="fixs.inviteShow">
            <img src="@/assets/images/fixed-invite.gif" alt="" srcset="" />

            <!-- <CloseCircleOutlined
              @click="onHideFix('inviteShow')"
              class="p-rel"
              style="z-index: 2"
            /> -->
          </div>
          <!-- <div class="p-rel">
            <img src="@/assets/images/fixed-box.gif" alt="" srcset="" />
            <CloseCircleOutlined />
          </div>
          <div class="p-rel">
            <img src="@/assets/images/fixed-turntable.gif" alt="" srcset="" />
            <CloseCircleOutlined />
          </div>
          <div class="p-rel">
            <img src="@/assets/images/fixed-red_package.gif" alt="" srcset="" />
            <CloseCircleOutlined />
          </div> -->
          <div
            class="p-rel"
            @click="$router.push('/service')"
            v-if="fixs.serviceShow"
          >
            <img src="@/assets/images/fixed-server.png" alt="" srcset="" />
            <!-- <CloseCircleOutlined
              @click="onHideFix('serviceShow')"
              class="p-rel"
              style="z-index: 2"
            /> -->
          </div>
        </div>
      </div>
    </div>
    <UcModal ref="tip" :notBg="true">
      <div>
        <img
          src="@/assets/images/popup1.png"
          style="width: 100%; object-fit: contain"
          alt=""
          srcset=""
        />
      </div>
    </UcModal>
  </div>
</template>

<script>
import LayoutHeader from "../layout/layout-header";
import LayoutLeft from "../layout/layout-left";
import LayoutFooter from "../layout/layout-footer";
import { PlusOutlined } from "@ant-design/icons-vue";
import { message } from "ant-design-vue";
import { Swiper, SwiperSlide } from "swiper/vue"; // swiper所需组件
// 这是分页器和对应方法，swiper好像在6的时候就已经分离了分页器和一些其他工具
import { Autoplay, Navigation, Pagination, A11y } from "swiper";
import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/pagination";
const modules = [Autoplay, Pagination, Navigation, A11y];
import { NotificationFilled } from "@ant-design/icons-vue";
import {
  CloseCircleOutlined,
  SoundOutlined,
  PlayCircleOutlined,
  DoubleRightOutlined,
  CloseOutlined,
  GiftFilled,
  DollarCircleFilled,
  CrownFilled,
  CustomerServiceFilled,
} from "@ant-design/icons-vue";
// import { createNamespacedHelpers } from "vuex"
// const { mapState, mapGetters, mapMutations, mapActions } =
//   createNamespacedHelpers("global")
import { CountTo } from "vue3-count-to";
import base64 from "@/assets/base64";

import Dianzi from "@/views/game/dianzi";
import Hot from "@/views/game/hot";

export default {
  name: "App",
  components: {
    CountTo,
    LayoutHeader,
    LayoutLeft,
    LayoutFooter,
    NotificationFilled,
    CloseCircleOutlined,
    SoundOutlined,
    PlayCircleOutlined,
    DoubleRightOutlined,
    CloseOutlined,
    GiftFilled,
    DollarCircleFilled,
    CrownFilled,
    CustomerServiceFilled,
    Dianzi,
    Hot,
  },
  data() {
    return {
      base64,
      text: "首次存款奖金高达$7777　　　邀请朋友，你可以赚取$10　　　欢迎来到Tascaslot，巴西在线娱乐平台，高额奖金，大额存款提款无忧",
      animateText: true,
      tabKey: 0,
      downloadShow: true,
      count: {
        startVal: 2135756,
        endVal: 2139283, //2139283
        maxVal: 869496334364,
        minVal: 2135756,
      },
      fixs: {
        inviteShow: true,
        serviceShow: true,
      },
    };
  },
  // computed: {
  //   ...mapState(["menu"]),
  // },
  created() {
    this.$store.dispatch("global/loadTabs");
    // this.$refs.tip.show()
    // console.log(
    //   "menu",
    //   this.$store.state.global.menu,
    //   this.$store.state.global.menu.length
    // );
    setInterval(() => {
      this.count.endVal += 800;
      if (this.count.endVal > this.count.maxVal) {
        this.count.startVal = this.count.minVal;
      }
    }, 5000);
  },
  methods: {
    onBannerChange() {
      // console.log("onBannerChange");
    },
    omMoreGame(item) {
      item.gameShow = true;
      console.log("onBannerChange");
    },
    toAgent() {
      this.$router.push({ path: "/agent", query: { back: true } });
    },
    onChangeTab(key) {
      this.$refs[`#${key}`][0].click();
      this.$store.commit("global/setState", { attr: "tabKey", state: key });
    },
    onDownloadShow() {
      this.downloadShow = false;
    },
    onHideFix(attr) {
      this.fixs[attr] = false;
    },
  },
};
</script>

<style lang="less" scoped>
.home {
  background-color: #40b5d7;
  background-image: url("@/assets/images/home-bg.png"); /* 替换为你的图片路径 */
  // background-position: center center;
  background-repeat: repeat;
  // background-attachment: fixed;
  // background-size: cover;
  // overflow-y: scroll;
  height: calc(100vh - 80px);
  &-bg {
    background-image: url("@/assets/images/home-wheat-left.png"),
      url("@/assets/images/home-wheat-right.png");
    background-position: left top, right top;
    background-repeat: no-repeat, no-repeat;
    padding-top: 10px;
  }
  &-wrap {
    max-width: 1200px;
    min-width: 1000px;
    padding: 0 40px 30px 40px;
    margin: 0 auto;
    position: relative;
  }
  .banner {
    img {
      width: 100%;
      object-fit: contain;
      border-radius: 10px;
      cursor: pointer;
    }
  }
  .notice {
    background: #0093b1;
    position: relative;
    padding: 6px 12px;
    border-radius: 8px;
  }
  .jackpot {
    .stats {
      width: 100%;
      height: 100px;
      // object-fit: contain;
    }
    .laohu {
      width: 264px;
      height: 269px;
    }
  }
  .category {
    position: relative;
    width: 100%;
    margin-top: 3px;
    background-color: #0093b1;
    border-radius: 8px;
    padding: 0 20px 10px 20px;
  }

  // 广播文字
  .scroll-container {
    overflow: hidden;
    white-space: nowrap;
  }

  .text {
    display: inline-block;
    animation: slide-left 20s linear infinite;
  }

  @keyframes slide-left {
    0% {
      transform: translateX(100%);
    }
    100% {
      transform: translateX(-100%);
    }
  }

  .text-slide-enter-active,
  .text-slide-leave-active {
    transition: all 0.5s;
  }

  .text-slide-enter-from,
  .text-slide-leave-to {
    opacity: 0;
    transform: translateX(-100%);
  }

  // 菜单分类
  .category {
    :deep(.ant-tabs-top-content) {
      display: none;
    }
    &__item:last-child {
      border-bottom: none;
    }
    &__item {
      border-bottom: 1px solid #1be4ff;
      padding-bottom: 12px;
      &--title {
        width: 100%;
        line-height: 24px;
        margin-bottom: 30px;
        padding-top: 30px;
        flex: 1;
        display: flex;
        align-items: center;
        font-size: 20px;
        font-weight: 400;
        color: #fff;
        img {
          width: 24px;
          margin-right: 8px;
        }
      }
    }
    .game-list {
      &__item {
        width: 138px;
        height: 182px;
        margin-right: 18px;
        cursor: pointer;
        transition: transform 0.3s ease;
        .p-abs {
          // display: none;
          transition: all 0.3s;
          opacity: 0;
          background: rgba(0, 0, 0, 1);
          width: 100%;
          height: 100%;
          z-index: 4;
          left: 0;
          top: 0;
        }
        img {
          width: 100%;
          object-fit: contain;
          transition: transform 0.3s ease;
        }
      }
      &__item:hover {
        img {
          transform: scale(1.1);
        }
        .p-abs {
          opacity: 0.7;
        }
      }
      &__item:nth-child(7n + 7) {
        margin-right: 0px;
      }
    }
  }

  // 附属导航
  .s-nav {
    margin-top: 10px;
    .item {
      padding: 18px 0;
      display: flex;
      flex: 1;
      align-items: center;
      justify-content: center;
      color: #fff;
      border-radius: 4px;
      margin-right: 4px;
      span {
        margin-left: 8px;
      }
    }
  }

  // 固定元素
  .home-fixed {
    margin-right: 10px;
    position: relative;
    z-index: 9;
    img {
      width: 100px;
      margin-bottom: 10px;
      cursor: pointer;
    }
    .p-rel {
      z-index: 9;
      .anticon {
        position: absolute;
        right: -2px;
        top: -2px;
        color: rgba(255, 255, 255, 0.7);
        cursor: pointer;
        font-size: 13px;
      }
    }
  }
}
</style>
