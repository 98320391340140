<template>
  <a-popover v-model:visible="visible" :placement="direction" trigger="hover">
    <template #content>
      <div class="lang-list p-tb-10">
        <div
          @click="onChangeLang(i)"
          class="lang-item color-default p-tb-10 p-lr-15 pointer"
          v-for="(i, index) in $enums.languageType.options()"
        >
          {{ i.label }}
        </div>
      </div>
    </template>
    <slot></slot>
  </a-popover>
</template>

<script>
export default {
  name: "uc-lang",
  props: {
    direction: {
      type: String,
      default: "bottom",
    },
  },

  data() {
    return {
      visible: false,
    };
  },
  methods: {
    onChangeLang(item) {
      this.$i18n.locale = item.lang;
      this.visible = false;
    },
  },
};
</script>

<style lang="less">
.lang-item:hover {
  background: rgba(0, 0, 0, 0.1);
}
</style>
