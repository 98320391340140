<template>
  <div class="info-edit modal-page popup-mount w-fill flex flex-1 flex-dc">
    <div class="modal-wrap flex flex-1">
      <div class="modal-content flex flex-1 flex-dc">
        <p class="t-center fs-18 color-white m-b-20">编辑</p>
        <div class="p-rel w-fill flex flex-dc" style="height: 600px">
          <div class="wrap h-fill flex flex-dc flex-sb">
            <van-cell-group>
              <van-field
                readonly
                input-align="right"
                v-model="form.account"
                label="账号"
              />
              <van-field
                input-align="right"
                v-model="form.name"
                label="昵称"
                placeholder="请输入用户昵称"
              />
              <van-field
                input-align="right"
                readonly
                clearable
                v-model:value="form.cardType"
                label="性别"
                class="p-rel"
              >
                <!-- 自定义输入内容后的按钮 -->
                <template #input>
                  <van-dropdown-menu>
                    <van-dropdown-item
                      v-model="form.cardType"
                      :options="option1"
                    />
                  </van-dropdown-menu>
                </template>
              </van-field>
            </van-cell-group>
          </div>
          <div class="m-t-30">
            <div
              @click="handleSubmit"
              style="height: 40px"
              class="p-lr-10 bor-8 fs-16 bgs-default color-main pointer flex flex-center m-l-15"
            >
              保存
            </div>
          </div>
          <!-- popup -->
          <van-popup
            v-model:show="show"
            position="bottom"
            :style="{ height: '60%' }"
            :get-container="getContainer"
          >
            <div class="flex flex-dc p-15 h-fill" style="color: #96edfe">
              <!-- time -->
              <div
                class="time flex flex-sb m-b-12 p-b-12"
                style="border-bottom: 1px solid var(--sub)"
              >
                <div
                  style="border: 1px solid #1be4ff; width: 32%"
                  @click="onChangeFilter1(i, 0)"
                  v-for="(i, index) in timeFilters"
                  class="filter-item"
                  :class="i.cur ? 'cur' : ''"
                >
                  {{ i.label }}
                </div>
              </div>
              <!-- other -->
              <div
                class="other flex flex-sb flex-1 flex-wrap"
                style="overflow: auto"
              >
                <div
                  v-for="(i, index) in filters"
                  @click="onChangeFilter2(i, index)"
                  class="filter-item m-b-10"
                  style="border: 1px solid #1be4ff; width: 48%"
                  :class="i.cur ? 'cur' : ''"
                >
                  {{ i.label }}
                </div>
              </div>
              <!-- btn -->
              <div class="p-t-12">
                <div
                  @click="handleFilter"
                  style="height: 40px"
                  class="bor-8 fs-15 bgs-default color-main pointer flex flex-center"
                >
                  筛选
                </div>
              </div>
            </div>
          </van-popup>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { FunnelPlotOutlined } from "@ant-design/icons-vue"

export default {
  name: "record",
  components: {
    FunnelPlotOutlined,
  },
  data() {
    return {
      show: false,
      form: {
        account: 1111,
        name: undefined,
        sex: 1,
        level: 3,
        avatar: "",
      },
      option1: [
        { text: "全部商品", value: 0 },
        { text: "新款商品", value: 1 },
        { text: "活动商品", value: 2 },
      ],
    }
  },
  methods: {
    handleSubmit() {
      console.log("8888", this.form)
      this.show = false
    },
    showPopup() {
      this.show = true
    },
  },
}
</script>

<style lang="less">
.record {
  .van-popup {
    position: absolute !important;
    background: #0093b1;
  }
}
.filter-item {
  padding: 3px 0;
  text-align: center;
  cursor: pointer;
}
.filter-item.cur {
  color: #fff;
  border: 1px solid #fff;
}
</style>
