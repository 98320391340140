<template>
  <div
    class="notice modal-page popup-mount w-fill flex flex-1 flex-dc"
    style="background: var(--main)"
  >
    <div class="modal-wrap flex flex-1">
      <div class="modal-content flex flex-1 flex-dc">
        <p class="t-center fs-18 color-white">通知详情</p>
        <div
          id="record"
          class="record p-rel w-fill flex flex-dc"
          style="height: 600px"
        >
          <div class="record-wrap h-fill flex flex-dc">
            <!-- record-list -->
            <div class="color-hight">
              <p class="tit fs-16 t-center">
                {{ $store.state.notice.document.title }}
              </p>
              <p
                class="sub-tit fs-14 t-center"
                v-if="$store.state.notice.document.time_year"
              >
                {{
                  $dayjs($store.state.notice.document.time_year * 1000).format(
                    "YYYY-MM-DD HH:mm:ss"
                  )
                }}
              </p>
              <div class="content">
                {{ $store.state.notice.document.txtContent }}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { FunnelPlotOutlined, RightOutlined } from "@ant-design/icons-vue"

export default {
  name: "record",
  components: {
    FunnelPlotOutlined,
    RightOutlined,
  },
  data() {
    return {
      document: {},
    }
  },
  // async created() {
  //   let res = await this.$store.dispatch("notice/getDetails", {
  //     data_id: this.$store.state.notice.id,
  //   })
  //   this.document = res.Data.announcement[0]
  // },
  methods: {
    handleFilter() {
      this.show = false
    },
    showPopup() {
      this.show = true
    },
  },
}
</script>

<style lang="less"></style>
